import { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import ReactPiwik, { Location } from 'react-piwik';

import useAuth from '../hooks/auth';
import Layout from './Layout';
import OverviewPage from '../pages/Overview';
import OverviewCoopPage from '../pages/OverviewCoop';
import OverviewProvPage from '../pages/OverviewProv';
import AdminPage from '../pages/Admin';
import AdminLogsPage from '../pages/AdminLogs';
import Activity from '../pages/Activity';
import ContactPage from '../pages/Contact';
import RecommendationsPage from '../pages/Recommendation';
import DashboardPage from '../pages/Dashboard';
import ProductPage from '../pages/Product';
import LoginPage from '../pages/Login';
import RenewPasswordPage from '../pages/RenewPassword';
import ForgotPasswordPage from '../pages/ForgotPassword';
import UserProfilePage from '../pages/UserProfile';
import TriggeredEventsPage from '../pages/TriggeredEvents';
import PendingEventsPage from '../pages/PendingEvents';
import Historical from '../pages/Historical';
import DashboardProvincePage from '../pages/DashboardProvince';
import CoopManager from '../pages/cooperativeMangement/CoopManager';
import CoopDetails from '../pages/cooperativeMangement/cooperativeDetails/CoopDetails';
import { createBrowserHistory } from 'history';
import { History } from 'history';
import PolicyDetails from '../pages/cooperativeMangement/cooperativeDetails/policyDetails/PolicyDetails';

const matomoEnabled = !!process.env.REACT_APP_MATOMO_HOST;
const matomo =
  matomoEnabled
    ? new ReactPiwik({
      url: process.env.REACT_APP_MATOMO_HOST!,
      siteId: parseInt(process.env.REACT_APP_MATOMO_SITEID!),
    })
    : null;

const Child = ({ isAuthenticated, isAdmin, isSuperUser }: { isAuthenticated: boolean; isAdmin: boolean; isSuperUser: boolean; }) => {
  const history = createBrowserHistory() as History;

  useEffect(() => {
    if (matomoEnabled) {
      matomo!.connectToHistory({
        location: history.location,
        listen: function (listener: (location: Location, ...unknownArgs: unknown[]) => void): () => void {
          const _listener = (update: { location: Location }) => listener(update.location)
          return history.listen(_listener);
        }
      });
    }
  }, [history]);

  return (
    <>
      {!isAuthenticated && (
        <Routes>
          <Route path="/login/:result?" element={<LoginPage />} />
          <Route path="/renewPassword/:token" element={<RenewPasswordPage />} />
          <Route path="/initPassword/:token" element={<RenewPasswordPage />} />
          <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
          <Route path="*" element={<LoginPage />} />
        </Routes>
      )}
      {isAuthenticated && (
        <Layout>
          <Routes>
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/recommendations" element={<RecommendationsPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/dashboard/:provinceId" element={<DashboardProvincePage />} />
            <Route path="/dashboard/:provinceId/:insureeId" element={<DashboardProvincePage />} />
            <Route path="/product" element={<ProductPage />} />
            <Route path="/triggered-events" element={<TriggeredEventsPage />} />
            <Route path="/historical" element={<Historical />} />
            <Route path="/me" element={<UserProfilePage />} />
            {isAdmin && (
              <Route path="/admin/managment" element={<AdminPage />} />
            )}
            {isAdmin && (
              <Route path="/admin/logs" element={<AdminLogsPage />} />
            )}
            {isAdmin && (
              <Route path="/admin/activity" element={<Activity />} />
            )}
            {isAdmin && (
              <Route path="/pendingEvents" element={<PendingEventsPage />} />
            )}
            {(isSuperUser || isAdmin) && (
              <Route path="/overview" element={<OverviewPage />} />
            )}
            {(isSuperUser || isAdmin) && (
              <Route path="/overview/cooperatives/:coopId" element={<OverviewCoopPage />} />
            )}
            {(isSuperUser || isAdmin) && (
              <Route path="/provinceViewer" element={<OverviewProvPage />} />
            )}
            {(isSuperUser || isAdmin) && (
              <Route path="/coopManager" element={<CoopManager />} />
            )}
            {(isSuperUser || isAdmin) && (
              <Route path="/coopManager/:id" element={<CoopDetails />} />
            )}
            {(isSuperUser || isAdmin) && (
              <Route path="/coopManager/:coopId/policy/:policyId" element={<PolicyDetails />} />
            )}
            {(isSuperUser || isAdmin) && (
              <Route path="*" element={<Navigate to={'/overview'} replace />} />
            )}
            {!(isSuperUser || isAdmin) && (
              <Route path="*" element={<Navigate to={'/dashboard'} replace />} />
            )}
          </Routes>
        </Layout>
      )}
    </>
  );
};

const Router = () => {
  const {
    isAuthenticated,
    isSuperUser,
    isAdmin,
  } = useAuth();

  return (
    <BrowserRouter>
      <Child {...{ isAuthenticated, isAdmin, isSuperUser }} />
    </BrowserRouter>
  );
}

export default Router;
