import ApiService from './Api';
import EventsViewModel from '../models/EventsView';

class EventsView {

  getAllEvents(
    pageSize: number,
    pageNb: number,
    nameFilter: string,
    statusFilter?: string,
  ): Promise<[EventsViewModel[], number]> {
    return ApiService.get(`/eventsView?pageSize=${pageSize}&pageNb=${pageNb}&nameFilter=${nameFilter}${statusFilter ? `&statusFilter=${statusFilter}` : ''}`);
  }

  rejectEvent(weatherConditionId: number) {
    return ApiService.post('/eventsView/reject', { weatherConditionId })
  }

}

export default new EventsView();
