import { Paper } from "@mui/material";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import InteractiveMultiPolygon from "./interactiveMultiPolygon";
import { LatLng, LatLngExpression, Map } from "leaflet";
import HomeControl from "./homeControl";
import { Fragment, useCallback, useEffect, useState } from "react";
import { multiPolygon, AllGeoJSON, centroid, centerOfMass } from "@turf/turf";
import ProvinceArea from "../../models/Province-area";

interface Props {
  provinces: (ProvinceArea & { color: string })[];
  selectedProvince: number | undefined;
  onSelectProvice: (id: number) => any;
  resize?: boolean;
  disableInteraction?: boolean;
}

function InteractivePhilippinesMap({
  provinces,
  selectedProvince,
  onSelectProvice,
  resize,
}: Props) {
  const [map, setMap] = useState<Map>();
  const [center, setCenter] = useState<LatLngExpression>([
    12.9417921, 122.2861588,
  ]);
  const [zoom] = useState<number>(7);

  const onClickHome = useCallback(() => {
    map?.setView(center, zoom)
  }, [map, center, zoom]);

  useEffect(() => {
    if (selectedProvince) {
      const province = provinces.find((prov) => prov.id === selectedProvince);
      const center = centerOfMass(province?.path).geometry.coordinates;
      setCenter(new LatLng(center[0] - 1, center[1]));
    } else if (map && provinces.length > 0) {
      const collection: AllGeoJSON = {
        type: "FeatureCollection",
        features: [
          ...provinces
            .filter((p) => p.path)
            .map((p) => multiPolygon(p.path.coordinates)),
        ],
      };
      const coord = centroid(collection).geometry.coordinates;
      setCenter(new LatLng(coord[0], coord[1]));
    }
  }, [provinces, map, selectedProvince]);

  useEffect(() => {
    map?.setView(center, zoom);
  }, [center, zoom, map]);

  useEffect(() => {
    map?.invalidateSize();
  }, [resize, map]);

  return (
    <Paper
      sx={{
        m: 2,
        p: 2,
        width: "100%",
        "&>*": {
          height: "100%",
          minHeight: "800px",
          width: "100%",
          "& a.MuiButton-containedSecondary": {
            color: "#F6F6F8",
          },
        },
        "& path": {
          outline: "none",
        },
      }}
    >
      <MapContainer
        center={center}
        zoom={zoom}
        doubleClickZoom={false}
        whenCreated={(m) => {
          setMap(m);
        }}
        //maxBounds={latLngBounds(latLng(4.0, 116.0), latLng(21.0, 128.0))}
        maxBoundsViscosity={0.8}
        maxZoom={9}
        minZoom={7}
        zoomControl={false}
      >
        <ZoomControl position="topright" />
        <HomeControl onClick={onClickHome} />
        <TileLayer
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {provinces.map((province, index) => {
          if (province.path) {
            return (
              <InteractiveMultiPolygon
                className={`tableLink${index}`}
                id={province.id}
                key={`map-prov-poly-${province.id}`}
                color={province.color}
                multiPolygon={province.path}
                tooltip={province.name}
                isSelected={selectedProvince === province.id}
                onSelect={(id: number) => onSelectProvice(id)}
              />
            );
          } else {
            return <Fragment key={index} />;
          }
        })}
      </MapContainer>
    </Paper>
  );
}

export default InteractivePhilippinesMap;
