import { useState, createContext, ReactElement } from 'react';

interface Context {
  drawerOpened: boolean;

  setDrawerOpened: (state: boolean) => void;
}

const DrawerContext = createContext({ } as Context);

interface DrawerContextProviderProps {
  initialState: boolean;
  children: ReactElement | ReactElement[];
}

function DrawerContextProvider({ initialState, children }: DrawerContextProviderProps) {
  const [drawerOpened, setDrawerOpened] = useState<boolean>(initialState);

  const context: Context = {
    drawerOpened,
    setDrawerOpened
  };

  return (
    <DrawerContext.Provider value={context}>
      { children }
    </DrawerContext.Provider>
  );
};

export default DrawerContext;
export { DrawerContextProvider };
