import ApiService from './Api';
import WeatherConditionModel, { ConditionType } from '../models/WeatherCondition';

class WeatherCondition {
  getLastUpdate(
  ): Promise<{lastUpdate: string}> {
    return ApiService.get(`/weatherConditions/lastUpdate`);
  }

  getByProvince(id: string, type?: ConditionType): Promise<WeatherConditionModel[]> {
    return ApiService.get(`/weatherConditions/${id}${type ? `?type=${type}` : ''}`);
  }

  getByProvinceBetween(id: string, start: string, end: string, type?: ConditionType): Promise<WeatherConditionModel[]> {
    return ApiService.get(`/weatherConditions/${id}/between?start=${start}&end=${end}${type ? `&type=${type}` : ``}`);
  }
}

export default new WeatherCondition();
