import { Box, Typography, Button, Tooltip, TextField } from "@mui/material";

import Page from "../components/Page";
import SingleDataCard from "../components/SingleDataCard";
import ProvinceService from "../services/Province";
import CoverService from "../services/Cover";
import BenefitService from "../services/Benefit";
import UtilsService from "../services/Utils";
import { useCallback, useEffect, useState } from "react";
import useUser from "../hooks/user";
import { Role } from "../models/User";
import InsureeSelector from "../components/InsureeSelector";
import InteractivePhilippinesMap from "../components/InteractiveMap/InteractivePhilippinesMap";
import { green, grey } from "@mui/material/colors";
import DashboardProvincePopup from "../components/modals/DashboardProvincePopup";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import useDrawer from "../hooks/drawer";
import { ConditionType } from "../models/WeatherCondition";
import { NavLink as RouterLink } from "react-router-dom";
import ProvinceArea, { TriggerLevels } from "../models/Province-area";
import wcConfig from "../configurations/weatherConditions.json";
import Province from "../models/Province";

function Dashboard() {
  const { drawerOpened } = useDrawer();
  const { user, selectedInsureeId } = useUser();

  const [provinceAreas, setProvinceAreas] = useState<
    (ProvinceArea & { color: string })[]
  >([]);

  const [coverage, setCoverage] = useState<number>(0);
  const [totalBenefits, setTotalBenefits] = useState<number>(0);

  const [selectedProvinceId, setSelectedProvinceId] = useState<number>();
  const [selectedProvince, setSelectedProvince] = useState<Province>();

  const [coverageDate, setCoverageDate] = useState<moment.Moment | null>(
    moment()
  );

  const initInsureeView = useCallback(() => {
    CoverService.getMyTotalCoverage(coverageDate || moment()).then(
      (res) => {
        setCoverage(res.sum ? res.sum : 0);
      },
      (err) => {
        console.log(err);
      }
    );
    BenefitService.getMyTotalBenefitsValue().then(
      (res) => {
        setTotalBenefits(res.sum ? res.sum : 0);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [coverageDate]);

  const initNoneInsureeView = useCallback(
    async (id: number) => {
      CoverService.getTotalCoverageOfInsuree(
        id.toString(),
        coverageDate || moment()
      ).then(
        (res) => {
          setCoverage(res.sum ? res.sum : 0);
        },
        (err) => {
          console.log(err);
        }
      );
      BenefitService.getTotalBenefitsValueOfInsuree(id.toString()).then(
        (res) => {
          setTotalBenefits(res.sum ? res.sum : 0);
        },
        (err) => {
          console.log(err);
        }
      );
    },
    [coverageDate]
  );

  useEffect(() => {
    ProvinceService.getProvinceTriggerLevels(
      selectedInsureeId ?? undefined
    ).then((provinceTriggerLevels) => {
      ProvinceService.getAllProvinceAreas().then((res) => {
        const areas = res.filter((province) => {
          const trigger = provinceTriggerLevels.find(
            (prov) => prov.id === province.id
          );
          return trigger !== undefined;
        }).map((province) => {
          const trigger = provinceTriggerLevels.find(
            (prov) => prov.id === province.id
          )?.trigger;
          return {
            id: province.id,
            path: province.path,
            color:
              trigger === TriggerLevels.noTrigger
                ? green[500]
                : trigger === TriggerLevels.yellowTrigger
                ? wcConfig.triggers.yellowTrigger.color
                : trigger === TriggerLevels.orangeTrigger
                ? wcConfig.triggers.orangeTrigger.color
                : trigger === TriggerLevels.redTrigger
                ? wcConfig.triggers.redTrigger.color
                : grey[500],
            name: province.name,
          };
        });
        setProvinceAreas([...areas]);
      });
    });
    if (selectedInsureeId) {
      setSelectedProvinceId(undefined);
      if (user.role !== Role.Insuree) {
        initNoneInsureeView(selectedInsureeId);
      } else {
        initInsureeView();
      }
    }
  }, [
    selectedInsureeId,
    coverageDate,
    user.role,
    initNoneInsureeView,
    initInsureeView,
  ]);

  useEffect(() => {
    if (user.role !== Role.Insuree) {
      if (selectedInsureeId) {
        CoverService.getTotalCoverageOfInsuree(
          selectedInsureeId.toString(),
          coverageDate || moment()
        ).then(
          (res) => {
            setCoverage(res.sum ? res.sum : 0);
          },
          (err) => {
            console.log(err);
          }
        );
      }
    } else {
      CoverService.getMyTotalCoverage(coverageDate || moment()).then(
        (res) => {
          setCoverage(res.sum ? res.sum : 0);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, [selectedInsureeId, coverageDate, user.role]);

  useEffect(() => {
    if (selectedProvinceId) {
      if(user.role !== Role.Insuree && selectedInsureeId) {
        ProvinceService.getProvinceOfInsuree(selectedProvinceId.toString(), selectedInsureeId.toString()).then(setSelectedProvince)
      } else {
        ProvinceService.getMyProvince(selectedProvinceId.toString()).then(setSelectedProvince)
      }
    } else {
      setSelectedProvince(undefined);
    }
  }, [selectedInsureeId, selectedProvinceId, user.role]);

  return (
    <Box sx={{ height: (theme) => `calc(100vh - ${theme.spacing(6)})` }}>
      <Page title={`Dashboard`}>
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            width: "fit-content",
          }}
        >
          <SingleDataCard
            title={"Coverage"}
            content={`${UtilsService.formatCurrency(coverage)}`}
            sx={{
              m: 0,
              mb: 1,
              boxShadow: "none",
              border: "2px solid #00000033",
            }}
          />
          <SingleDataCard
            title={"Total Payouts"}
            content={`${UtilsService.formatCurrency(totalBenefits)}`}
            sx={{
              m: 0,
              mb: 1,
              boxShadow: "none",
              border: "2px solid #00000033",
            }}
          />
          {user.role !== Role.Insuree && (
            <Box
              sx={{
                p: 2,
                backdropFilter: "blur(6px)",
                backgroundColor: "#ffffff60",
                borderRadius: "3px",
                overflow: "hidden",
                border: "2px solid #00000033",
              }}
            >
              <InsureeSelector />
              <DatePicker
                label="Coverage Date"
                value={coverageDate}
                onChange={(v) => setCoverageDate(v)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth variant="standard" />
                )}
              />
            </Box>
          )}
        </Box>
        {selectedProvinceId && selectedProvince ? (
          <>
            <Box
              sx={{
                position: "relative",
                zIndex: 1,
                width: "100%",
                padding: "16px",
                backdropFilter: "blur(6px)",
                backgroundColor: "#ffffff60",
                borderRadius: "8px",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "auto",
                marginBottom: "8px",
                border: "2px solid #00000033",
              }}
            >
              <Tooltip title="More info">
                <Button
                  variant="contained"
                  color="secondary"
                  component={RouterLink}
                  to={`/dashboard/${selectedProvince.id}/${selectedInsureeId}`}
                >
                  {selectedProvince?.name}
                </Button>
              </Tooltip>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box style={{ width: "48%" }}>
                  <Typography variant="h6" style={{ textAlign: "center" }}>
                    Three days cumulative rainfall
                  </Typography>
                  <DashboardProvincePopup
                    province={selectedProvince}
                    type={ConditionType.rain}
                  />
                </Box>
                <Box style={{ width: "48%" }}>
                  <Typography variant="h6" style={{ textAlign: "center" }}>
                    Wind speed
                  </Typography>
                  <DashboardProvincePopup
                    province={selectedProvince}
                    type={ConditionType.wind}
                  />
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <></>
        )}
        <Box
          style={{
            position: "absolute",
            zIndex: 0,
            top: 0,
            left: drawerOpened ? "240px" : "72px",
            width: `calc(100% - ${drawerOpened ? "240px" : "72px"})`,
            height: "100%",
          }}
        >
          <Box
            p={3}
            id="interactiveMap"
            sx={{
              width: "100%",
              height: "100%",
              m: 0,
              p: 0,
              "& > *": {
                width: "100%",
                height: "100%",
                m: 0,
                p: 0,
              },
              "& > div": {
                m: 0,
                p: 0,
                boxShadow: "none",
                height: "100vh",
              },
            }}
          >
            <InteractivePhilippinesMap
              provinces={provinceAreas}
              selectedProvince={selectedProvinceId}
              onSelectProvice={setSelectedProvinceId}
              resize={drawerOpened}
            />
          </Box>
        </Box>
      </Page>
    </Box>
  );
}

export default Dashboard;
