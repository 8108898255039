import Page from '../components/Page';
import SingleDataCard from '../components/SingleDataCard';
import CustomTable from '../components/CustomTable';
import useUser from '../hooks/user';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Role } from '../models/User';
import InsureeService from '../services/Insuree';
import BenefitService from '../services/Benefit';
import WeatherConditionService from '../services/WeatherCondition';
import UtilsService from '../services/Utils';
import InsureeModel from '../models/Insuree';
import moment from 'moment';
import { ConditionType } from '../models/WeatherCondition';

const headers = [
  { index: 'province', title: 'Province' },
  { index: 'coverage', title: 'Coverage', transformer: (v: number) => UtilsService.formatCurrency(v) },
  { index: 'date', title: 'Date of Trigger', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'type', title: 'Type' },
  { index: 'value', title: 'Value', transformer: (amount: number, type: ConditionType) => UtilsService.formatWeatherCondition(type, amount) },
  { index: 'trigger', title: 'Trigger' },
  { index: 'benefit', title: 'Payout', transformer: (v: number) => UtilsService.formatCurrency(v) }
]

interface Content {
  id: number,
  province: string,
  coverage: number,
  date: string,
  type: string,
  value: number,
  trigger: string,
  benefit: number
}

function TriggeredEvents() {
  const { user } = useUser()
  const [lastUpdate, setLastUpdate] = useState<string>('-');
  const [totalBenefits, setTotalBenefits] = useState<number>(0);
  const [insurees, setInsurees] = useState<InsureeModel[]>([]);
  const [insuree, setInsuree] = useState<number>(0);

  const initInsureeView = () => {
    BenefitService.getMyTotalBenefitsValue().then((res) => {
      setTotalBenefits(res.sum ? res.sum : 0);
    },
      err => { console.log(err) }
    );
  }

  const initNoneInsureeView = (id: number) => {
    BenefitService.getTotalBenefitsValueOfInsuree(id.toString()).then((res) => {
      setTotalBenefits(res.sum ? res.sum : 0);
    },
      err => { console.log(err) }
    );
  }

  const updateBenefitsAsInsuree = useCallback((pageNb: number, pageSize: number, filter: string) => {
    return BenefitService.getMyBenefits(pageSize, pageNb).then(res => {
      const contents = res[0].map(b => {
        const content: Content = {
          id: b.id,
          province: b.cover.province.name,
          coverage: parseFloat(b.cover.amount),
          date: moment(b.date).format('DD MMM YYYY'),
          type: b.weatherCondition.index.name,
          value: parseFloat(b.weatherCondition.value),
          trigger: b.trigger,
          benefit: parseFloat(b.amount)
        };
        return content;
      });
      return { total: res[1], rows: contents }
    });
  }, []);

  const updateBenefitsNotAsInsuree = useCallback((pageNb: number, pageSize: number, filter: string) => {
    return BenefitService.getBenefitsOfInsuree(`${insuree}`, pageSize, pageNb).then(res => {
      const contents = res[0].map(b => {
        const content: Content = {
          id: b.id,
          province: b.cover.province.name,
          coverage: parseFloat(b.cover.amount),
          date: b.date,
          type: b.weatherCondition.index.name,
          value: parseFloat(b.weatherCondition.value),
          trigger: b.trigger,
          benefit: parseFloat(b.amount)
        };
        return content;
      });
      return { total: res[1], rows: contents }
    });
  }, [insuree]);

  useEffect(() => {
    if (user.role === Role.Insuree) {
      initInsureeView();
    } else {
      InsureeService.getAllInsurees(true, true)
        .then(res => {
          setInsurees(res);
          setInsuree(res[0].id);
          initNoneInsureeView(res[0].id);
        },
          err => { console.log(err) });
    }
    WeatherConditionService.getLastUpdate()
      .then(res => {
        setLastUpdate(res.lastUpdate);
      },
        err => { console.log(err) }
      );
  }, [user.role]);

  useEffect(() => {
    if (user.role !== Role.Insuree) {
      initNoneInsureeView(insuree);
    }
  }, [insuree, user.role])

  const handleChange = (event: SelectChangeEvent<number>) => {
    setInsuree(event.target.value as number);
  };

  return (
    <Page title="Triggered Events">
      <>
        {user.role !== Role.Insuree &&
          <FormControl variant='standard' sx={{ m: 2 }}>
            <InputLabel id="demo-simple-select-label">Cooperatives</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={insurees.length > 0 ? insuree : ''}
              onChange={handleChange}
              autoWidth
            >
              {insurees.map((i, index) => (
                <MenuItem key={index} value={i.id} selected={insuree === i.id}>{i.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        }
      </>
      <Box sx={{ display: 'flex' }}>
        <SingleDataCard title={'Total Payouts'} content={`${UtilsService.formatCurrency(totalBenefits)}`} />
        <SingleDataCard title={'Last Update'} content={UtilsService.formatDate(lastUpdate)} />
      </Box>
      <CustomTable
        headers={headers}
        updateFunction={user.role === Role.Insuree ? updateBenefitsAsInsuree : updateBenefitsNotAsInsuree}
        disableSearch={true}
      />
    </Page>
  );
}

export default TriggeredEvents;