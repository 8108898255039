import { useState } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Snackbar,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import useAuth from '../hooks/auth';
import AuthenticateService from '../services/Authenticate';
import { useEffect } from 'react';
import _envConfig from '../configurations/env.json';
import { Env } from '../configurations/env';

interface FormData {
  email: string;
  password: string;
}

function Login() {
  const { result } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<String | null>(null);
  const { register, formState: { errors }, handleSubmit } = useForm<FormData>();
  const { onLogin } = useAuth();
  // for snackbar
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [failure, setFailure] = useState(true);

  const env: Env = (process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'dev') as Env;
  const envConfig = _envConfig.themes.filter(t => t.envs.includes(env))[0];

  useEffect(() => {
    if (result != null) {
      if (result === 'PASSWORD_SET_OK') {
        setFailure(false);
        setMessage('Password successfully set !');
      } else if (result === 'PASSWORD_SET_KO') {
        setFailure(true);
        setMessage('Password setting failed, try again later.');
      }
      setOpen(true);
    }
  }, [])

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const onSubmit = ({ email, password }: FormData) => {
    setError(null);
    setLoading(true);
    AuthenticateService
      .login(email, password)
      .then(onLogin)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError('Authentication failed, please check your credentials');
      });
  };

  return (
    <Container maxWidth="sm">
      <Box mt={3} display="flex" flexDirection="column" alignItems="center">
        <img
          alt="Rainfall Platform"
          src={envConfig.logo_on_light}
          style={{ width: '50%' }}
        />
        <Box mt={1} style={{ width: '100%' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email address"
              autoComplete="email"
              variant="outlined"
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              {...register('email', {
                required: 'This field is required',
              })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              autoComplete="current-password"
              variant="outlined"
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              {...register('password', {
                required: 'This field is required',
              })}
            />
            {error !== null && (
              <Box>
                <Typography color="error">
                  {error}
                </Typography>
              </Box>
            )}
            <Box mt={2} display="flex" flexDirection="column" alignItems="center">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Log in
              </Button>
              <a href="/forgotPassword">Forgot your password ?</a>
            </Box>
          </form>
        </Box>
      </Box>
      <Snackbar
        ContentProps={{
          className: `${failure ? 'KO' : 'OK'}`, sx: {
            textAlign: 'center',
            display: 'block',
            '&.KO': {
              backgroundColor: '#f44336',
            },
            '&.OK': {
              backgroundColor: 'secondary.main',
            }
          }
        }}
        open={open}
        autoHideDuration={6000}
        onClose={() => handleClose()}
        message={message}
      />
    </Container>
  );
}

export default Login;