import ApiService from './Api';
import BenefitModel from '../models/Benefit';
import BenefitCreationModel from '../models/creationModels/BenefitsFromEvent';
import { TemporalityEnum } from '../components/TemporalityToggleButtons';
import { PolicyStatus } from '../models/Policy';

class Benefit {

  getAllBenefits(
    pageSize: number,
    pageNb: number,
    temporality?: TemporalityEnum,
  ): Promise<[BenefitModel[], number]> {
    return ApiService.get(`/benefits?pageSize=${pageSize}&pageNb=${pageNb}${temporality ? `&temporality=${temporality}` : ``}`);
  }

  getBenefitsOfInsuree(
    id: string,
    pageSize: number,
    pageNb: number
  ): Promise<[BenefitModel[], number]> {
    return ApiService.get(`/benefits/ofInsuree/${id}?pageSize=${pageSize}&pageNb=${pageNb}`);
  }

  getMyBenefits(
    pageSize: number,
    pageNb: number
  ): Promise<[BenefitModel[], number]> {
    return ApiService.get(`/benefits/me?pageSize=${pageSize}&pageNb=${pageNb}`);
  }

  getTotalBenefitsValue(
    status?: PolicyStatus,
    temporality?: TemporalityEnum,
  ): Promise<{sum: number}> {
    return ApiService.get(`/benefits/total${(status || temporality) ? `?` : ``}${status ? `status=${status}` : ``}${(status && temporality) ? `&` : ``}${temporality ? `temporality=${temporality}` : ``}`);
  }

  getTotalBenefitsValueOfInsuree(
    id: string
  ): Promise<{sum: number}> {
    return ApiService.get(`/benefits/total/ofInsuree/${id}`);
  }

  getMyTotalBenefitsValue(
  ): Promise<{sum: number}> {
    return ApiService.get(`/benefits/total/me`);
  }

  getTotalBenefitsValueOfInsureeForProvince(
    insureeId: string,
    provinceId: string,
  ): Promise<{sum: number}> {
    return ApiService.get(`/benefits/total/ofInsuree/${insureeId}/${provinceId}`);
  }

  getTotalBenefitsValueByProvince(
    provinceId: string,
    status?: PolicyStatus,
    temporality?: TemporalityEnum,
  ): Promise<{sum: number}> {
    return ApiService.get(`/benefits/total/province/${provinceId}${(status || temporality) ? `?` : ``}${status ? `status=${status}` : ``}${(status && temporality) ? `&` : ``}${temporality ? `temporality=${temporality}` : ``}`);
  }

  getMyTotalBenefitsValueForProvince(
    provinceId: string,
  ): Promise<{sum: number}> {
    return ApiService.get(`/benefits/total/me/${provinceId}`);
  }

  createBenefitsWithEvent(
    body: BenefitCreationModel
  ): Promise<void> {
    return ApiService.post(`/benefits/create`, body);
  }

  deleteBenefitsFromEvent(eventId: number): Promise<void> {
    return ApiService.delete(`/benefits/delete/${eventId}`)
  }

}

export default new Benefit();
