import ApiService from './Api';
import ProvinceModel from '../models/Province';
import ProvinceCreationModel from '../models/creationModels/Province';
import ProvinceUpdateModel from '../models/updateModels/Province';
import ProvinceAreaModel, { TriggerLevels } from '../models/Province-area';
import { PolicyStatus } from '../models/Policy';
import { TemporalityEnum } from '../components/TemporalityToggleButtons';

class Province {

  getAllProvinces(): Promise<ProvinceModel[]> {
    return ApiService.get(`/provinces/`);
  }

  getAllProvinceAreas(): Promise<ProvinceAreaModel[]> {
    return ApiService.get(`/provinces/areas`);
  }
  getProvinceTriggerLevels(insureeId?: number): Promise<{ id: number; trigger: TriggerLevels }[]> {
    return ApiService.get(`/provinces/areas/trigger-levels${insureeId ? `?insuree=${insureeId}` : ''}`);
  }

  getAllProvincesPaginated(
    pageSize: number,
    pageNb: number,
    nameFilter: string,
    withCovers?: boolean,
    withBenefits?: boolean,
  ): Promise<[ProvinceModel[], number]> {
    return ApiService.get(`/provinces/paginated?pageSize=${pageSize}&pageNb=${pageNb}&nameFilter=${nameFilter}${withCovers ? '&withCovers=true' : ''}${withBenefits ? '&withBenefits=true' : ''}`);
  }

  getReinsuranceCapacity(
    status?: PolicyStatus,
    temporality?: TemporalityEnum,
  ): Promise<{Province_id: number, Province_name: string, Province_reinsuranceCapacity: string, coverage: string}[]> {
    const searchParams = new URLSearchParams(
      {
        ...(status ? { status } : {}),
        ...(temporality ? { temporality } : {}),
      }
    )
    return ApiService.get(`/provinces/reinsuranceCapacity?${searchParams.toString()}`);
  }

  getProvincesOfInsuree(
    id: string,
    pageSize: number,
    pageNb: number,
    nameFilter: string
  ): Promise<[ProvinceModel[], number]> {
    return ApiService.get(`/provinces/ofInsuree/${id}?pageSize=${pageSize}&pageNb=${pageNb}&nameFilter=${nameFilter}`);
  }

  getProvinceOfInsuree(
    provinceId: string,
    insureeId: string,
  ): Promise<ProvinceModel> {
    return ApiService.get(`/provinces/${provinceId}/ofInsuree/${insureeId}`);
  }

  getMyProvinces(
    pageSize: number,
    pageNb: number,
    nameFilter: string
  ): Promise<[ProvinceModel[], number]> {
    return ApiService.get(`/provinces/me?pageSize=${pageSize}&pageNb=${pageNb}&nameFilter=${nameFilter}`);
  }

  getMyProvince(id: string): Promise<ProvinceModel> {
    return ApiService.get(`/provinces/me/${id}`);
  }

  createProvince(province: ProvinceCreationModel): Promise<ProvinceModel> {
    return ApiService.post(`/provinces/create`, province);
  }

  updateProvince(province: ProvinceUpdateModel): Promise<ProvinceModel> {
    return ApiService.post(`/provinces/update`, province);
  }
  
  deleteProvince(provinceId: number) {
    return ApiService.delete(`/provinces/delete/${provinceId}`);
  }

}

export default new Province();
