import queryString from 'query-string';
import ActivityModel from '../models/Activity';
import ApiService from './Api';

class Activity {

  get(pageSize?: number, pageNb?: number): Promise<[ActivityModel[], number]> {
    const url = queryString.stringifyUrl({ url: "/activity", query: { pageSize: pageSize, pageNb: pageNb } });
    return ApiService.get(url);
  }

}

export default new Activity();
