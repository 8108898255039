import { Paper, AppBar, Tabs, Tab, Box } from "@mui/material";
import Page from "../components/Page";
import SingleDataCard from "../components/SingleDataCard";
import CustomTable, {
  RowActions,
  RowButtonAction,
} from "../components/CustomTable";
import { useCallback, useEffect, useState } from "react";
import InsureeService from "../services/Insuree";
import CoverService from "../services/Cover";
import BenefitService from "../services/Benefit";
import WeatherConditionService from "../services/WeatherCondition";
import UtilsService from "../services/Utils";
import ProvinceService from "../services/Province";
import { useLocation } from "react-router-dom";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import WeatherFileService from "../services/WeatherFile";
import { TemporalityEnum } from "../components/TemporalityToggleButtons";
import { PolicyStatus } from "../models/Policy";
import PolicyStatusToggleButtons from "../components/PolicyStatusToggleButtons";

const headersCoops = [
  { index: "coopName", title: "Cooperative Name (link)", isLink: true },
  {
    index: "totalCoverage",
    title: "Total Coverage",
    transformer: (v: number) => UtilsService.formatCurrency(v),
  },
  {
    index: "lastTrigger",
    title: "Last Trigger Date",
    transformer: (d: string) => UtilsService.formatDate(d),
  },
  {
    index: "totalBenefits",
    title: "Total Payout",
    transformer: (v: number) => UtilsService.formatCurrency(v),
  },
];

const headersTriggers = [
  { index: "province", title: "Province" },
  {
    index: "date",
    title: "Date",
    transformer: (d: string) => UtilsService.formatDate(d),
  },
  { index: "trigger", title: "Trigger" },
  {
    index: "coops",
    title: "Cooperatives",
    subHeaders: [
      { index: "name", title: "Name" },
      {
        index: "benefit",
        title: "Payout",
        transformer: (v: number) => UtilsService.formatCurrency(v),
      },
    ],
  },
  { index: "actions", title: "Actions" },
];

const headersReinsurance = [
  { index: "name", title: "Province" },
  {
    index: "used",
    title: "total coverage per province",
    transformer: (v: number) => UtilsService.formatCurrency(v),
  },
];

interface ContentCoops {
  id: number;
  coopName: { value: string; link: string; state: { coopName: string } };
  totalCoverage: number;
  lastTrigger: string;
  totalBenefits: number;
}

interface ContentTriggers {
  id: number;
  weatherConditionId: number;
  province: string;
  date: string;
  trigger: string;
  coops: { name: string; benefit: number }[];
  actions: JSX.Element;
}

interface ContentReinsurance {
  id: number;
  name: string;
  used: number;
  isTotal?: boolean;
}

function Overview() {
  const { state } = useLocation();

  const [totalBenefits, setTotalBenefits] = useState<number>(0);
  const [totalCoverage, setTotalCoverage] = useState<number>(0);
  const [lastUpdate, setLastUpdate] = useState<string>("-");
  const [coopsTotal, setCoopsTotal] = useState<number>(0);
  const [tabValue, setTabValue] = useState(state?.tabNb ?? 0);

  const [tempo] = useState<TemporalityEnum>(TemporalityEnum.current);
  const [policyStatus, setPolicyStatus] = useState<PolicyStatus>(PolicyStatus.inForce);

  useEffect(() => {
    BenefitService.getTotalBenefitsValue(policyStatus, tempo).then(
      (res) => {
        setTotalBenefits(res.sum ? res.sum : 0);
      },
      (err) => {
        console.log(err);
      }
    );
    WeatherConditionService.getLastUpdate().then(
      (res) => {
        setLastUpdate(res.lastUpdate ? res.lastUpdate : "-");
      },
      (err) => {
        console.log(err);
      }
    );
    InsureeService.getNumberOfInsurees(policyStatus, tempo).then(setCoopsTotal);
    CoverService.getTotalCoverage(policyStatus, tempo).then((res) => setTotalCoverage(res.sum));
  }, [tempo, policyStatus]);

  const updateReinsurance = useCallback(
    (pageNb: number, pageSize: number, filter: string) => {
      return ProvinceService.getReinsuranceCapacity(policyStatus, tempo).then((res) => {
        const totalCoverage: number = res.reduce(
          (prev, curr) =>
            prev + (parseFloat(curr.coverage) ? parseFloat(curr.coverage) : 0),
          0
        );
        const total: ContentReinsurance = {
          id: 0,
          name: "",
          used: totalCoverage,
          isTotal: true,
        };
        const values = res
          .map((r) => ({
            id: r.Province_id,
            name: r.Province_name,
            used: parseFloat(r.coverage),
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
        values.push(total);
        return { total: 0, rows: values };
      });
    },
    [policyStatus, tempo]
  );

  const updateCoops = useCallback(
    (pageNb: number, pageSize: number, filter: string) => {
      return InsureeService.getAllInsureesPaginated(
        pageSize,
        pageNb,
        filter,
        policyStatus,
        tempo
      ).then((res) => {
        const contents: ContentCoops[] = res[0].map((i) => {
          const benefits = i.policies
            .map((p) => p.covers.map((c) => c.benefits))
            .flat(2);
          const content = {
            id: i.id,
            coopName: {
              value: i.name,
              link: `/coopManager/${i.id}`,
              state: { coopName: i.name },
            },
            totalCoverage: i.policies.reduce(
              (sum, p) =>
                sum +
                p.covers.reduce(
                  (acc: number, curr) => acc + parseFloat(curr.amount),
                  0
                ),
              0
            ),
            lastTrigger:
              benefits.length > 0
                ? benefits.reduce((acc, curr) =>
                    acc.date > curr.date ? acc : curr
                  ).date
                : "-",
            totalBenefits:
              benefits.length > 0
                ? benefits.reduce(
                    (acc: number, curr) => acc + parseFloat(curr.amount),
                    0
                  )
                : 0,
          };
          return content;
        });
        return {
          total: res[1],
          rows: contents.sort((a, b) =>
            a.coopName.value.localeCompare(b.coopName.value)
          ),
        };
      });
    },
    [policyStatus, tempo]
  );

  const openFile = useCallback(async (weatherConditionId: number) => {
    const fileData = await WeatherFileService.getCurrentFileByCondition(
      weatherConditionId
    );
    const blob = new Blob([fileData], {
      type: "application/pdf",
    });
    const objectUrl = URL.createObjectURL(blob);
    window.open(objectUrl);
  }, []);

  const actions = useCallback(
    (weatherConditionId: number, hasPdf: boolean): RowButtonAction[] => [
      {
        click: () => openFile(weatherConditionId),
        disabled: !hasPdf,
        icon: <PdfIcon />,
        color: "primary",
        tooltip: hasPdf ? "Download trigger confirmation" : "No pdf available",
      },
    ],
    [openFile]
  );

  const updateTriggers = useCallback(
    async (pageNb: number, pageSize: number, filter: string) => {
      return BenefitService.getAllBenefits(pageSize, pageNb, tempo).then((res) => {
        const contents: ContentTriggers[] = [];
        res[0].forEach((b) => {
          const weatherCondition = contents.find(
            (c) => c.weatherConditionId === b.weatherCondition.id
          );
          if (weatherCondition !== undefined) {
            weatherCondition.coops.push({
              name: b.cover.policy.insuree.name,
              benefit: parseFloat(b.amount),
            });
          } else {
            contents.push({
              id: b.id,
              weatherConditionId: b.weatherCondition.id,
              province: b.cover.province.name,
              date: b.date,
              trigger: b.trigger,
              coops: [
                {
                  name: b.cover.policy.insuree.name,
                  benefit: parseFloat(b.amount),
                },
              ],
              actions: (
                <RowActions
                  elementId={b.id}
                  actions={actions(b.weatherCondition.id, b.hasPdf)}
                />
              ),
            });
          }
        });
        return {
          total: res[1],
          rows: contents.sort((a, b) => (a.date < b.date ? 1 : -1)),
        };
      });
    },
    [actions, tempo]
  );

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const a11yProps = (index: any) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  return (
    <Page title="Overview">
      <Box sx={{ display: "flex" }}>
        <PolicyStatusToggleButtons
          color="primary"
          onChange={(value) => setPolicyStatus(value)}
        />
        <SingleDataCard
          title={"Number of Cooperatives"}
          content={coopsTotal.toString()}
        />
        <SingleDataCard
          title={"Total Coverage"}
          content={`${UtilsService.formatCurrency(totalCoverage)}`}
        />
        <SingleDataCard
          title={"Total Payouts"}
          content={`${UtilsService.formatCurrency(totalBenefits)}`}
        />
        <SingleDataCard
          title={"Last Update"}
          content={UtilsService.formatDate(lastUpdate)}
        />
      </Box>
      <Paper sx={{ m: 2 }}>
        <AppBar position="static" color="default">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
          >
            <Tab
              sx={{ fontWeight: "bold" }}
              label="Covered Cooperatives"
              {...a11yProps(0)}
            />
            <Tab
              sx={{ fontWeight: "bold" }}
              label="Triggered Events"
              {...a11yProps(1)}
            />
            <Tab
              sx={{ fontWeight: "bold" }}
              label="Provinces Overview"
              {...a11yProps(2)}
            />
          </Tabs>
        </AppBar>
        <Box
          p={3}
          hidden={tabValue !== 0}
          sx={{
            "& > div": {
              m: 0,
              p: 0,
              boxShadow: "none",
            },
          }}
        >
          <CustomTable headers={headersCoops} updateFunction={updateCoops} />
        </Box>
        <Box
          p={3}
          hidden={tabValue !== 1}
          sx={{
            "& > div": {
              m: 0,
              p: 0,
              boxShadow: "none",
            },
          }}
        >
          <CustomTable
            headers={headersTriggers}
            updateFunction={updateTriggers}
            disableSearch={true}
          />
        </Box>
        <Box
          p={3}
          hidden={tabValue !== 2}
          sx={{
            "& > div": {
              m: 0,
              p: 0,
              boxShadow: "none",
            },
          }}
        >
          <CustomTable
            headers={headersReinsurance}
            updateFunction={updateReinsurance}
            disablePagination
            disableSearch
          />
        </Box>
      </Paper>
    </Page>
  );
}

export default Overview;
