import ApiService from './Api';
import LogModel from '../models/Log';

class Log {
  getLogsPaginated(
    pageSize: number,
    pageNb: number,
    messageFilter: string,
    levelFilter: string,
    metaFilter: string,
  ):Promise<[LogModel[], number]> {
    return ApiService.get(`/logs?pageSize=${pageSize}&pageNb=${pageNb}&messageFilter=${messageFilter}&levelFilter=${levelFilter}&metaFilter=${metaFilter}`);
  }  
}

export default new Log();
