import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  TablePagination,
  TextField,
  debounce,
  Chip,
  Box
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { useCallback, useEffect, useState } from 'react';
import {
  NavLink as RouterLink, useSearchParams,
} from 'react-router-dom';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.default,
    fontWeight: 'bold'
  },
}))(TableCell);

interface CustomTableProps {
  headers: any[];
  content: any[];
  total: number;
  pageSize: number;
  pageNb: number;
  onChangePage: Function;
  onChangeRowsPerPage: Function;
  onChangeMessageSearch: Function;
  onChangeMetaSearch: Function;
  onChangeLogLevel: Function;
  disableSearch?: boolean;
  pageSizeOptions?: number[];
}

function CustomLogsTable({
  headers,
  content,
  total,
  pageSize,
  pageNb,
  onChangePage,
  onChangeRowsPerPage,
  onChangeMessageSearch,
  onChangeMetaSearch,
  onChangeLogLevel,
  disableSearch = false,
  pageSizeOptions = [10, 25, 50, 100]
}: CustomTableProps) {
  const [messageSearch, setMessageSearch] = useState('');
  const [metaSearch, setMetaSearch] = useState('');
  const [levels, setLevels] = useState(['verbose', 'debug', 'info', 'warning', 'error']);

  const [searchParams] = useSearchParams();

  useEffect(() => setMetaSearch(searchParams.get('meta') ?? ''), [searchParams])

  const changeMessageSearch = useCallback(
    debounce((name: string) => {
      onChangeMessageSearch(name);
    }, 200),
    []
  );
  const changeMetaSearch = useCallback(
    debounce((name: string) => {
      onChangeMetaSearch(name);
    }, 200),
    []
  );

  useEffect(() => {
    changeMessageSearch(messageSearch);
  }, [messageSearch]);

  useEffect(() => {
    changeMetaSearch(metaSearch);
  }, [metaSearch]);

  useEffect(() => {
    onChangeLogLevel(levels.toString());
  }, [levels]);

  const handleChangePage = (event: unknown, newPage: number) => {
    onChangePage(pageSize, newPage);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeRowsPerPage(+event.target.value);
  }

  const handleChangeMessageSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageSearch(event.target.value);
  }

  const handleChangeMetaSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMetaSearch(event.target.value);
  }

  const handleChangeLevel = (lvl: string) => {
    if (levels.includes(lvl)) {
      setLevels(levels.filter(l => l !== lvl));
    } else {
      setLevels(levels.concat(lvl));
    }
  }

  return (
    <Paper sx={{ m: 2, p: 2 }}>
      {!disableSearch && (
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: 2
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}>
            <Chip
              label="verbose"
              sx={{ color: 'background.paper', m: 1, backgroundColor: '#b884e3', opacity: levels.includes('verbose') ? 'default' : 0.5 }}
              onClick={() => handleChangeLevel('verbose')}
            />
            <Chip
              label="debug"
              sx={{ color: 'background.paper', m: 1, backgroundColor: '#4caf50', opacity: levels.includes('debug') ? 'default' : 0.5 }}
              onClick={() => handleChangeLevel('debug')}
            />
            <Chip
              label="info"
              sx={{ color: 'background.paper', m: 1, backgroundColor: '#757575', opacity: levels.includes('info') ? 'default' : 0.5 }}
              onClick={() => handleChangeLevel('info')}
            />
            <Chip
              label="warning"
              sx={{ color: 'background.paper', m: 1, backgroundColor: '#fbc02d', opacity: levels.includes('warning') ? 'default' : 0.5 }}
              onClick={() => handleChangeLevel('warning')}
            />
            <Chip
              label="error"
              sx={{ color: 'background.paper', m: 1, backgroundColor: '#f50057', opacity: levels.includes('error') ? 'default' : 0.5 }}
              onClick={() => handleChangeLevel('error')}
            />
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}>
            <TextField
              size="small"
              label="Search message"
              variant="outlined"
              color="secondary"
              onChange={handleChangeMessageSearch}
              sx={{ mr: 1 }}
            />
            <TextField
              size="small"
              label="Search metadata"
              variant="outlined"
              color="secondary"
              onChange={handleChangeMetaSearch}
              value={metaSearch}
            />
          </Box>
        </Box>
      )}
      <TableContainer sx={{
        border: theme => `solid 1px ${theme.palette.grey[300]}`,
        borderRadius: theme => theme.spacing(0.5),
      }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <StyledTableCell key={header.index} align="center">{header.title}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {content.map((row, index) => (
              <TableRow key={index}>
                {headers.map((header) => {
                  if (header.index === 'level') {
                    return (
                      <TableCell key={header.index} align="center">
                        <Chip label={row[header.index]} className={`${row[header.index]}`} sx={{
                          width: 'fit-content',
                          alignSelf: 'center',
                          m: 1,
                          color: 'background.paper',
                          '&.verbose': {
                            backgroundColor: '#b884e3'
                          },
                          '&.debug': {
                            backgroundColor: '#4caf50'
                          },
                          '&.info': {
                            backgroundColor: '#757575'
                          },
                          '&.warning': {
                            backgroundColor: '#fbc02d'
                          },
                          '&.error': {
                            backgroundColor: '#f50057'
                          }
                        }} />
                      </TableCell>
                    )
                  } else {
                    if (header.isLink) {
                      return (
                        <TableCell key={header.index} align="center" sx={{ '&>*': { textDecoration: 'none' } }}>
                          <RouterLink to={row[header.index].link}>
                            {row[header.index].value}
                          </RouterLink>
                        </TableCell>
                      )
                    } else {
                      if (header.transformer) {
                        return (
                          <TableCell key={header.index} align="center">{header.transformer(row[header.index])}</TableCell>
                        )
                      } else {
                        return (
                          <TableCell key={header.index} align="center">{row[header.index]}</TableCell>
                        )
                      }
                    }
                  }
                })}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                page={pageNb}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={pageSizeOptions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default CustomLogsTable;