import { Box, Button, Chip, Tab, Tabs, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import PolicyDetailsCoversPanel from "./PolicyDetails.covers.panel";
import PolicyDetailsFilesPanel from "./PolicyDetails.files.panel";
import Page from "../../../../components/Page";
import InsureeService from "../../../../services/Insuree";
import PolicyService from "../../../../services/Policy";
import Insuree from "../../../../models/Insuree";
import Policy, { PolicyStatus } from "../../../../models/Policy";
import UtilsService from "../../../../services/Utils";
import moment from "moment";
import SingleDataCard from "../../../../components/SingleDataCard";

function PolicyDetails() {
  const { coopId, policyId } = useParams();
  const navigate = useNavigate();
  const [coop, setCoop] = useState<Insuree>();
  const [policy, setPolicy] = useState<Policy>();

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = useCallback((event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  }, []);

  useEffect(() => {
    const _coopId = parseInt(coopId ?? '0');
    if (!isNaN(_coopId)) {
      InsureeService.getById(_coopId).then(setCoop);
    }
  }, [coopId]);

  useEffect(() => {
    const _policyId = parseInt(policyId ?? '0');
    if (!isNaN(_policyId)) {
      PolicyService.getById(_policyId).then(setPolicy);
    }
  }, [policyId]);

  const a11yProps = useCallback((index: any) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }, []);

  return (
    <Page title="Cooperative Manager">
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          variant="contained"
          sx={{ mr: 3 }}
          startIcon={<BackIcon />}
          onClick={() => navigate(`/coopManager/${coopId}`)}
        >
          Back
        </Button>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {coop && (<Typography variant="h5">{coop.name}</Typography>)}
        </Box>      
      </Box>
      {policy ? (
        <Box sx={{ display: "flex", alignItems: "center", my: 3, mx: -2 }}>
          <SingleDataCard title="Start" content={UtilsService.formatDate(moment(policy.start).toISOString())}/>
          <SingleDataCard title="End" content={UtilsService.formatDate(moment(policy.end).toISOString())}/>
          <SingleDataCard title="Status" content={UtilsService.toFirstLettersUpperCase(policy.status)}/>
        </Box>
      ) : <></>}
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label='Covers' {...a11yProps(0)} />
        <Tab label='Files' {...a11yProps(1)} />
      </Tabs>
      <Box>
        {coop && (
          <>
            <Box sx={{ m: -2 }} hidden={tabValue !== 0}>
              <PolicyDetailsCoversPanel policyId={parseInt(policyId ?? '0')} />
            </Box>
            <Box sx={{ m: -2 }} hidden={tabValue !== 1}>
              <PolicyDetailsFilesPanel policyId={parseInt(policyId ?? '0')} />
            </Box>
          </>
        )}
      </Box>
    </Page>
  )
}

export default PolicyDetails;