import { useState, useEffect, useCallback } from 'react';
import {
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Box,
  TextField,
  debounce,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContent,
} from '@mui/material';
import ActiveIcon from '@mui/icons-material/CheckCircle';
import InactiveIcon from '@mui/icons-material/NotInterested';
import RequestRenewPasswordIcon from '@mui/icons-material/Autorenew';
import ChangeCoopIcon from '@mui/icons-material/Link';
import ChangeRoleIcon from '@mui/icons-material/EmojiEvents';
import AddIcon from '@mui/icons-material/Add';

import UserService from '../../services/User';
import User, { Role } from '../../models/User';
import CreateUser from '../modals/CreateUser';
import InsureeService from '../../services/Insuree';
import Insuree from '../../models/Insuree';
import { withStyles } from '@mui/styles';
import { confirmAlert } from 'react-confirm-alert';

const getRoleName = (role: string) => {
  switch (role) {
    case 'admin': return 'Administrator';
    case 'super-user': return 'CLIMBS';
    case 'insuree': return 'User';
  }
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.default,
    border: 'solid 1px #e0e0e0',
    fontWeight: 'bold'
  },
}))(TableCell);

const ConfirmChangeUserStatus = ({
  user,
  inactive,
  onClose,
  onConfirm,
}: {
  user: User;
  inactive: boolean;
  onConfirm: Function;
  onClose: Function;
}) => {
  return (
    <Dialog open={true}>
      <DialogTitle>
        {inactive
          ? `Are you sure to disable access for user ${user.email}?`
          : `Are you sure to enable access for user ${user.email}?`
        }
      </DialogTitle>
      <DialogActions>
        <Button
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          onClick={() => onConfirm()}
          color="primary"
          variant="contained"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
};

const ChangeInsureeForUser = ({
  user,
  onClose,
  onConfirm,
}: {
  user: User;
  onConfirm: Function;
  onClose: Function;
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedInsureeId, selectInsureeId] = useState<number>(user.insuree !== null ? user.insuree.id : -1);
  const [insurees, setInsurees] = useState<Insuree[]>([]);

  useEffect(() => {
    InsureeService
      .getAllInsurees(false, false)
      .then((insurees) => {
        setInsurees(insurees.sort((a, b) => a.name.localeCompare(b.name)));
      });
  }, []);

  const requestChangeUserInsuree = useCallback(() => {
    return UserService.changeUserInsuree(user.id, selectedInsureeId !== -1 ? selectedInsureeId : null)
  }, [selectedInsureeId, user.id]);

  return (
    <Dialog open={true}>
      <DialogTitle>
        Select new cooperative to link to this user:
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2 }}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">Cooperatives</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedInsureeId || -1}
              autoWidth
              onChange={(evt: any) => selectInsureeId(evt.target.value)}
            >
              <MenuItem key={-1} value={-1}>N/A</MenuItem>
              {insurees.map((insuree) => (
                <MenuItem key={insuree.id} value={insuree.id}>{insuree.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose()}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            setLoading(true);
            requestChangeUserInsuree().then(() => {
              onConfirm();
            }).finally(() => {
              setLoading(false);
            });
          }}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog >
  )
};

const ChangeUserRole = ({
  user,
  onClose,
  onConfirm,
}: {
  user: User;
  onConfirm: Function;
  onClose: Function;
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedRole, selectRole] = useState<Role>(user.role);

  const requestChangeUserRole = useCallback(() => {
    return UserService.changeUserRole(user.id, selectedRole)
  }, [selectedRole, user.id]);

  return (
    <Dialog open={true}>
      <DialogTitle>
        Select new role for this user:
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2 }}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">Roles</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedRole}
              autoWidth
              onChange={(evt: any) => selectRole(evt.target.value)}
            >
              {Object.values(Role).map((role, index) => (
                <MenuItem key={index} value={role}>{role}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose()}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            setLoading(true);
            requestChangeUserRole().then(() => {
              onConfirm();
            }).finally(() => {
              setLoading(false);
            });
          }}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
};

const UsersList = ({ }) => {
  const [users, setUsers] = useState({ count: 0, values: [] as User[] });
  const [pagination, setPagination] = useState({
    nbResults: 10,
    page: 0,
    search: '',
  });
  const [changeUserStatus, setChangeUserStatus] = useState<{ user: User, inactive: boolean } | null>(null);
  const [changeUserCooperative, setChangeUserCooperative] = useState<User | null>(null);
  const [changeUserRole, setChangeUserRole] = useState<User | null>(null);
  const [isAddUser, setAddUser] = useState(false);

  const [search, setSearch] = useState('');
  const changeSearch = useCallback(
    debounce((email: string) => {
      setPagination({
        ...pagination,
        page: 0,
        search: email,
      });
    }, 200),
    []
  );
  useEffect(() => {
    changeSearch(search);
  }, [changeSearch, search])

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }

  const loadUsers = () => {
    UserService
      .getAllUsers(pagination.nbResults, pagination.page, pagination.search)
      .then((users) => {
        setUsers(users);
      });
  };

  useEffect(loadUsers, [pagination]);

  const handleChangePage = (_event: any, newPage: number) => {
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({
      ...pagination,
      nbResults: +event.target.value,
    });
  };

  const confirmChangeUserStatus = (user: User, inactive: boolean) => {
    UserService
      .changeUserStatus(user.id, !inactive)
      .then(() => {
        setChangeUserStatus(null);
        loadUsers();
      });
  };

  const confirmRequestRenewUserPassword = (user: User) => {
    confirmAlert({
      title: 'Confirm delete',
      message: 'Are you sure you want to delete this user ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => UserService
            .requestRenewUserPassword(user.email)
            .then(() => loadUsers())
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  };

  return (
    <Paper sx={{ m: 2, p: 2 }}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">
          Users
        </Typography>
        <Button
          startIcon={<AddIcon />}
          variant='contained' color='secondary'
          onClick={() => setAddUser(true)}
        >
          Add
        </Button>
      </Box>
      <Box display="flex">
        <TextField
          size="small"
          label="Search by email"
          variant="outlined"
          color="secondary"
          value={search}
          onChange={handleChangeSearch}
        />
      </Box>
      <TableContainer sx={{
        border: theme => `solid 1px ${theme.palette.grey[300]}`,
        borderRadius: theme => theme.spacing(0.5),
        marginTop: 2,
      }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                Email
              </StyledTableCell>
              <StyledTableCell>
                Name
              </StyledTableCell>
              <StyledTableCell>
                Role
              </StyledTableCell>
              <StyledTableCell>
                Active
              </StyledTableCell>
              <StyledTableCell>
                Cooperative
              </StyledTableCell>
              <StyledTableCell align="center">
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.values.map((user) => (
              <TableRow key={user.id}>
                <TableCell>
                  {user.email}
                </TableCell>
                <TableCell>
                  {user.name}
                </TableCell>
                <TableCell>
                  {getRoleName(user.role)}
                </TableCell>
                <TableCell>
                  {user.active
                    ? <ActiveIcon />
                    : <InactiveIcon />
                  }
                </TableCell>
                <TableCell>
                  {user.insuree?.name || 'N/A'}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    title="Request renew password"
                    onClick={() => confirmRequestRenewUserPassword(user)}
                    size="large">
                    <RequestRenewPasswordIcon />
                  </IconButton>
                  {user.active && (
                    <IconButton
                      title="Switch to INACTIVE"
                      onClick={() => setChangeUserStatus({ user, inactive: true })}
                      size="large">
                      <InactiveIcon />
                    </IconButton>
                  )}
                  {user.active === false
                    && (
                      user.role !== Role.Insuree
                      || (user.role === Role.Insuree && user.insuree !== null)
                    )
                    && (
                      <IconButton
                        title="Switch to ACTIVE"
                        onClick={() => setChangeUserStatus({ user, inactive: false })}
                        size="large">
                        <ActiveIcon />
                      </IconButton>
                    )}
                  {user.role === Role.Insuree && (
                    <IconButton
                      title="Change cooperative"
                      onClick={() => setChangeUserCooperative(user)}
                      size="large">
                      <ChangeCoopIcon />
                    </IconButton>
                  )}
                  {user.role !== Role.Admin && (
                    <IconButton title="Change role" onClick={() => setChangeUserRole(user)} size="large">
                      <ChangeRoleIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={users.count}
                page={pagination.page}
                rowsPerPage={pagination.nbResults}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {changeUserStatus !== null && (
        <ConfirmChangeUserStatus
          user={changeUserStatus.user}
          inactive={changeUserStatus.inactive}
          onConfirm={() => confirmChangeUserStatus(changeUserStatus.user, changeUserStatus.inactive)}
          onClose={() => setChangeUserStatus(null)}
        />
      )}
      {changeUserCooperative !== null && (
        <ChangeInsureeForUser
          user={changeUserCooperative}
          onConfirm={() => {
            loadUsers();
            setChangeUserCooperative(null);
          }}
          onClose={() => setChangeUserCooperative(null)}
        />
      )}
      {changeUserRole !== null && (
        <ChangeUserRole
          user={changeUserRole}
          onConfirm={() => {
            loadUsers();
            setChangeUserRole(null);
          }}
          onClose={() => setChangeUserRole(null)}
        />
      )}
      {isAddUser && (
        <CreateUser
          isAdmin
          onClose={(reload) => {
            setAddUser(false);
            if (reload) loadUsers();
          }}
        />
      )}
    </Paper>
  );
};

export default UsersList;