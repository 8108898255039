import ApiService from './Api';
import CoverModel from '../models/Cover';
import CoverCreateModel from '../models/creationModels/Cover';
import CoverUpdateModel from '../models/updateModels/Cover';
import moment from 'moment';
import { PolicyStatus } from '../models/Policy';
import { TemporalityEnum } from '../components/TemporalityToggleButtons';

class Cover {

  getPaginatedCovers(
    nbResultsPerPage: number,
    page: number,
    provinceFilter?: string,
    policyId?: number,
  ): Promise<{ values: CoverModel[], count: number }> {
    const searchParams = new URLSearchParams(
      {
        pageNb: `${page}`,
        pageSize: `${nbResultsPerPage}`,
        ...(provinceFilter ? { provinceFilter } : {}),
        ...(policyId ? { policyId: `${policyId}` } : {})
      }
    )
    return ApiService.get(`/covers?${searchParams.toString()}`)
      .then(([values, count]) => ({ count, values }));
  }

  getTotalCoverage(
    status?: PolicyStatus,
    temporality?: TemporalityEnum,
  ): Promise<{ sum: number }> {
    const searchParams = new URLSearchParams(
      {
        ...(status ? { status } : {}),
        ...(temporality ? { temporality } : {}),
      }
    )
    return ApiService.get(`/covers/total?${searchParams.toString()}`);
  }

  getCoversByProvince(
    id: string,
    status?: PolicyStatus,
    temporality?: TemporalityEnum,
    pageSize?: number,
    pageNb?: number,
    provinceFilter?: string
  ): Promise<{ values: CoverModel[], count: number }> {
    const searchParams = new URLSearchParams(
      {
        ...(status ? { status } : {}),
        ...(temporality ? { temporality } : {}),
        ...(pageSize ? { pageSize: `${pageSize}` } : {}),
        ...(pageNb ? { pageNb: `${pageNb}` } : {}),
        ...(provinceFilter ? { provinceFilter } : {}),
      }
    )
    return ApiService.get(`/covers/province/${id}?${searchParams.toString()}`)
      .then(([values, count]) => ({ count, values }));
  }

  getMyCovers(
    withBenefits: boolean,
    withProvinces: boolean
  ): Promise<CoverModel[]> {
    return ApiService.get(`/covers/me?benefits=${withBenefits}&provinces=${withProvinces}`);
  }

  getTotalCoverageOfInsuree(
    id: string,
    date?: moment.Moment
  ): Promise<{ sum: number }> {
    return ApiService.get(`/covers/total/ofInsuree/${id}${date ? `?date=${date.format('YYYY-MM-DD')}` : ``}`);
  }

  getMyTotalCoverage(
    date?: moment.Moment
  ): Promise<{ sum: number }> {
    return ApiService.get(`/covers/total/me${date ? `?date=${date.format('YYYY-MM-DD')}` : ``}`);
  }

  getTotalCoverageOfInsureeForProvince(
    insureeId: string,
    provinceId: string
  ): Promise<{ sum: number }> {
    return ApiService.get(`/covers/total/ofInsuree/${insureeId}/${provinceId}`);
  }

  getMyTotalCoverageForProvince(
    provinceId: string
  ): Promise<{ sum: number }> {
    return ApiService.get(`/covers/total/me/${provinceId}`);
  }

  createCover(cover: CoverCreateModel): Promise<CoverModel> {
    return ApiService.post(`/covers/create`, cover);
  }

  updateCover(cover: CoverUpdateModel): Promise<CoverModel> {
    return ApiService.post(`/covers/update`, cover);
  }

  deleteCover(coverId: number): Promise<CoverModel> {
    return ApiService.delete(`/covers/delete/${coverId}`);
  }

}

export default new Cover();
