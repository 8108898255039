import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import User from './models/User';
import reportWebVitals from './reportWebVitals';

import ApiService from './services/Api';
import UserService from './services/User';

const init: () => Promise<{ user: User | null }> = () => {
  const refreshToken = ApiService.getStoreRefreshToken();
  if (!refreshToken) return Promise.resolve({ user: null });
  return ApiService
    .refreshTokens(refreshToken)
    .then(() => UserService.me())
    .then((user) => ({ user }))
    .catch(() => ({ user: null }));
};

init()
  .then((initialState) => {
    ReactDOM.render(
      <App user={initialState.user} />,
      document.querySelector('#root')
    );
  });

reportWebVitals();
