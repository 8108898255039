import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PolicyService from "../../../services/Policy";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import defaultPolicyDatesConfig from '../../../configurations/defaultPolicyDates.json';
import Policy from "../../../models/Policy";

interface PolicyForm {
  start: any,
  end: any,
}

const emptyPolicyForm: PolicyForm = {
  start: moment(defaultPolicyDatesConfig.start),
  end: moment(defaultPolicyDatesConfig.end),
}

interface Props {
  policy?: Policy;
  coopId: number;
  onConfirm: (id: number) => void;
  onClose: Function;
  opened: boolean
}

function PolicyDialog({
  policy,
  coopId,
  onClose: _onClose,
  onConfirm,
  opened,
}: Props) {
  const [loading, setLoading] = useState(false);
  const { control, formState: { errors, isValid }, handleSubmit, reset } = useForm<PolicyForm>({ mode: "onChange" });

  const onClose = useCallback(() => {
    _onClose();
    reset(emptyPolicyForm);
  }, [_onClose, reset]);

  const onSubmit = (data: PolicyForm) => {
    setLoading(true);
    if (policy) {
      PolicyService.updatePolicy(policy.id, {
        start: moment(data.start).format('YYYY-MM-DD'),
        end: moment(data.end).format('YYYY-MM-DD'),
      })
        .then((res) => onConfirm(policy.id))
        .catch(console.error)
        .finally(() => setLoading(false))
      setLoading(false)
    } else {
      PolicyService.createPolicy({
        insureeId: coopId,
        start: moment(data.start).format('YYYY-MM-DD'),
      })
        .then((res) => onConfirm(res.id))
        .catch(console.error)
        .finally(() => setLoading(false))
    }
  };

  return (
    <Dialog open={opened}>
      <DialogTitle>
        {policy ? "Edit policy" : "Create new policy"}
      </DialogTitle>
      <DialogContent>
        <form id="policyForm" onSubmit={handleSubmit(onSubmit)}>
          <FormControl variant="outlined" fullWidth margin="normal">
            <Controller
              control={control}
              name="start"
              defaultValue={policy ? moment(policy.start) : moment(defaultPolicyDatesConfig.start)}
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <DatePicker
                  label='Start of policy'
                  {...field}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth />
                  )}
                />
              )}
            />
            {errors.start && errors.start.message && (
              <FormHelperText error>
                {errors.start.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl variant="outlined" fullWidth margin="normal">
            <Controller
              control={control}
              name="end"
              defaultValue={policy ? moment(policy.end) : moment(defaultPolicyDatesConfig.end)}
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <DatePicker
                  label='End of policy'
                  {...field}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth />
                  )}
                />
              )}
            />
            {errors.end && errors.end.message && (
              <FormHelperText error>
                {errors.end.message}
              </FormHelperText>
            )}
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose()}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          form="policyForm"
          color="primary"
          variant="contained"
          disabled={loading || !isValid}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default PolicyDialog;