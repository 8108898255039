import ApiService from './Api';

class Mail {

  sendContact(email: string, name: string, message: string): Promise<void> {
    return ApiService.post(`/contacts/`, {
      email,
      name,
      message
    });
  }

}

export default new Mail();