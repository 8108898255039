import { useCallback, useState } from "react";
import ActiveIcon from '@mui/icons-material/CheckCircle';
import InactiveIcon from '@mui/icons-material/NotInterested';
import RemoveIcon from '@mui/icons-material/CancelRounded';
import RequestRenewPasswordIcon from '@mui/icons-material/Autorenew';
import CustomTable, { RowActions, RowButtonAction } from "../../../components/CustomTable";
import UserService from "../../../services/User";
import User from "../../../models/User";
import CreateUser from "../../../components/modals/CreateUser";
import { confirmAlert } from "react-confirm-alert";

const headersUsers = [
  { index: 'email', title: 'Email' },
  { index: 'name', title: 'Name' },
  { index: 'active', title: 'Active' },
  { index: 'actions', title: 'Actions' },
];

interface ContentUser {
  id: number,
  email: string,
  name: string,
  active: JSX.Element,
  actions: JSX.Element
};

interface Props {
  coopId: number,
}
function CoopDetailsUsersPanel({ coopId }: Props) {
  const [isAddUser, setIsAddUser] = useState(false);

  const [reloadData, setReloadData] = useState(false);

  const confirmRequestRenewUserPassword = useCallback((email: string) => {
    confirmAlert({
      title: 'Confirm password reset',
      message: 'Are you sure you want to request a password renewal for this user ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => UserService.requestRenewUserPassword(email)
            .then(() => {
              setReloadData((reload) => !reload);
            }),
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  }, []);

  const confirmRemoveUser = useCallback((userId: number) => {
    confirmAlert({
      title: 'Confirm removal',
      message: 'Are you sure you want to remove this user\'s access ? The user will be set inactive and will be unable to login on the platform again.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => UserService.changeUserInsuree(userId, null)
            .then(() => {
              setReloadData((reload) => !reload);
            })
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  }, []);

  const actions = useCallback((user: User): RowButtonAction[] => [
    { // Renew password button
      click: () => confirmRequestRenewUserPassword(user.email),
      disabled: false,
      icon: <RequestRenewPasswordIcon />,
      color: "primary",
      tooltip: "Send password renewal request",
    },
    { // Remove user from coop
      click: () => confirmRemoveUser(user.id),
      disabled: false,
      icon: <RemoveIcon />,
      color: "error",
      tooltip: "Remove user from cooperative",
    },
  ], [confirmRequestRenewUserPassword, confirmRemoveUser]);

  const updateUsers = useCallback((pageNb: number, pageSize: number, filter: string) => {
    return UserService.getCoopUsers(coopId, pageSize, pageNb, filter).then(res => {
      const contents: ContentUser[] = res.values.map(i => (
        {
          id: i.id,
          email: i.email,
          name: i.name,
          active: i.active ? <ActiveIcon /> : <InactiveIcon />,
          actions: <RowActions elementId={i.id} actions={actions(i)} />
        }
      ));
      return { total: res.count, rows: contents };
    });
  }, [coopId, actions]);

  return (
    <>
      <CustomTable
        headers={headersUsers}
        updateFunction={updateUsers}
        reloadData={reloadData}
        pageSizeOptions={[5, 10, 15]}
        searchLabel="User email"
        addButtonAction={() => setIsAddUser(true)}
        addButtonLabel="Add User"
        noDataText="No users."
      />
      {isAddUser && (
        <CreateUser
          onClose={(reload) => {
            setIsAddUser(false);
            reload && setReloadData((reload) => !reload);
          }}
          insureeId={coopId}
        />
      )}
    </>
  )
}

export default CoopDetailsUsersPanel;