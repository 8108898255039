import { useCallback, useState } from "react";
import HiddenIcon from '@mui/icons-material/VisibilityOff';
import ShownIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import CustomTable, { removeAction, RowActions, RowButtonAction, seeLogsAction } from "../../../../components/CustomTable";
import PolicyService from '../../../../services/Policy';
import PolicyFile from "../../../../models/PolicyFile";
import useUser from "../../../../hooks/user";
import { Role } from "../../../../models/User";
import AddPolicyFileButton from "../../../../components/AddPolicyFileButton";
import { useNavigate } from "react-router-dom";

interface ContentFiles {
  id: number,
  name: string,
  type: string,
  visibility: JSX.Element,
  actions: JSX.Element
};

interface Props {
  policyId: number,
}
function PolicyDetailsFilesPanel({ policyId }: Props) {
  const { user } = useUser();
  const navigate = useNavigate();
  const headersCovers = [
    { index: 'name', title: 'File name' },
    { index: 'type', title: 'Type' },
    { index: 'visibility', title: 'Visibility' },
    ...user.role === Role.Admin ? [{ index: 'actions', title: 'Actions' }] : [],
  ];
  const [reloadData, setReloadData] = useState(false);

  const toggleVisibility = useCallback((file: PolicyFile) => {
    PolicyService
      .updateFile(policyId, { id: file.id, update: { hidden: !file.hidden } })
      .then(() =>
        setReloadData((reload) => !reload)
      );
  }, [policyId]);

  const seeCoopFileLogsAction = useCallback((coopFileId: number) => {
    navigate(`/admin/logs?meta=insureeFile-${coopFileId}`);
  }, [navigate]);

  const handleDeleteFile = useCallback((fileId: number) => {
    PolicyService
      .deleteFile(policyId, fileId)
      .then(() =>
        setReloadData((reload) => !reload)
      );
  }, [policyId]);

  const toggleVisibilityAction = useCallback((file: PolicyFile): RowButtonAction => ({
    click: () => {
      toggleVisibility(file);
    },
    disabled: user.role !== Role.Admin,
    icon: !file.hidden ? <HiddenIcon /> : <ShownIcon />,
    color: "primary",
    tooltip: "Toggle file visibility"
  }), [toggleVisibility, user.role]);

  const downloadAction = useCallback((fileId: number): RowButtonAction => ({
    click: async () => {
      const fileData = await PolicyService.getFile(policyId, fileId);
      const blob = new Blob([fileData], {
        type: "application/pdf",
      });
      const objectUrl = URL.createObjectURL(blob);
      window.open(objectUrl);
    },
    disabled: false,
    icon: <DownloadIcon />,
    color: "primary",
    tooltip: "Download"
  }), [policyId])

  const updateCoops = useCallback((pageNb: number, pageSize: number, filter: string) => {
    return PolicyService.getFiles(policyId).then(res => {
      const contents: ContentFiles[] = res.map(file => (
        {
          id: file.id,
          name: file.filename,
          type: file.type,
          visibility: file.hidden ? <HiddenIcon /> : <ShownIcon />,
          actions: <RowActions elementId={file.id} actions={[
            toggleVisibilityAction(file),
            downloadAction(file.id),
            seeLogsAction(() => seeCoopFileLogsAction(file.id), user.role !== Role.Admin),
            removeAction(() => handleDeleteFile(file.id), user.role !== Role.Admin),
          ]} />
        })
      );
      return { total: 0, rows: contents };
    });
  }, [policyId, toggleVisibilityAction, downloadAction, user.role, seeCoopFileLogsAction, handleDeleteFile]);

  return (
    <CustomTable
      headers={headersCovers}
      updateFunction={updateCoops}
      reloadData={reloadData}
      disablePagination
      disableSearch
      addButtonElement={
        user.role === Role.Insuree ?
          undefined
          : <AddPolicyFileButton policyId={policyId} callBack={() => setReloadData((reload) => !reload)} />
      }
      addButtonLabel="Add File"
      addButtonDisabled={user.role !== Role.Admin}
      noDataText="No files."
    />
  )
}

export default PolicyDetailsFilesPanel;