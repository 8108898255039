import {
  Box,
  SxProps,
  Theme,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { PolicyStatus } from '../models/Policy';

interface Props {
  defaultValue?: PolicyStatus,
  onChange: (value: PolicyStatus) => void,
  color?: "primary" | "secondary",
  sx?: SxProps<Theme> | undefined,
}

function PolicyStatusToggleButtons({ defaultValue, onChange, color, sx }: Props) {
  const [selectedStatus, setSelectedStatus] = useState<PolicyStatus>(defaultValue ?? PolicyStatus.inForce);

  useEffect(() => {
    onChange(selectedStatus);
  }, [onChange, selectedStatus]);

  return (
    <Box sx={{
      m: theme => theme.spacing(0, 2),
      backgroundColor: 'background.paper',
      borderRadius: '3px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      ...sx
    }}>
      <FormControlLabel
        control={
          <Switch 
            color={color}
            checked={selectedStatus === PolicyStatus.inForce}
            onChange={(_, checked) => setSelectedStatus(checked ? PolicyStatus.inForce : PolicyStatus.pending)}
          />
        } 
        label={selectedStatus === PolicyStatus.inForce ? "In force" : "Pending"}
        labelPlacement="top"
        sx={{ whiteSpace: "nowrap", width: "4rem" }}
      />
    </Box>
  );
}

export default PolicyStatusToggleButtons;