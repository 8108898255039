import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import InsureeService from "../../../services/Insuree";
import Insuree from "../../../models/Insuree";

interface Props {
  coop?: Insuree,
  onConfirm: (id: number) => void;
  onClose: Function;
  opened: boolean
}
function CoopDialog({
  coop,
  onClose,
  onConfirm,
  opened,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [coopName, setCoopName] = useState<string>();

  const requestChangeUserRole = useCallback(() => {
    if (coopName !== undefined){
      if (coop) {
        return InsureeService.updateInsuree({ id: coop.id, update: { name: coopName } })
      } else {
        return InsureeService.createInsuree({name: coopName})
      }
    } else {
      return Promise.reject();
    }
  }, [coop, coopName]);

  return (
    <Dialog open={opened}>
      <DialogTitle>
        {coop ? 'Edit cooperative' : 'Create a new cooperative'}
      </DialogTitle>
      <DialogContent>
        <TextField
          label='Name'
          margin="dense"
          fullWidth
          onChange={(e) => { setCoopName(e.target.value) }}
          defaultValue={coop ? coop.name : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose()}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            setLoading(true);
            requestChangeUserRole().then((insuree) => {
              onConfirm(insuree.id);
            }).finally(() => {
              setLoading(false);
            });
          }}
          color="primary"
          variant="contained"
          disabled={loading || coopName === undefined}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default CoopDialog;