import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import wcConfig from '../configurations/weatherConditions.json';

const triggerColors = [
  wcConfig.triggers.yellowTrigger.color,
  wcConfig.triggers.orangeTrigger.color,
  wcConfig.triggers.redTrigger.color
]

interface TriggerDotProps {
  level: number; // 0 - yellow // 1 - orange // 2 - red
}

function TriggerDot({ level }: TriggerDotProps) {
  return (
    <FiberManualRecordIcon style={{
      color: triggerColors[level],
      verticalAlign: 'middle'
    }} />
  );
}

export default TriggerDot;