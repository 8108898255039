import {
  Card,
  CardHeader,
  Avatar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import useUser from '../hooks/user';

function UserTag() {
  const { user } = useUser();
  const navigate = useNavigate();
  return (
    <Card sx={{
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      cursor: 'pointer',
    }} onClick={() => navigate('/me')}>
      <CardHeader
        sx={{
          flexDirection: 'row-reverse',
          '& .MuiCardHeader-avatar': {
            marginRight: 0,
            marginLeft: '16px'
          },
          '& .MuiCardHeader-content': {
            textAlign: 'end'
          }
        }}
        avatar={
          <Avatar sx={{
            backgroundColor: 'secondary.main',
            color: 'background.default'
          }}>
            {user.name.charAt(0).toUpperCase()}
          </Avatar>
        }
        title={'Welcome, ' + user.name}
        subheader={user.email}
      />
    </Card>
  );
}

export default UserTag;