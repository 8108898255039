import { useCallback, useEffect, useMemo, useState } from "react";
import UtilsService from '../../../../services/Utils';
import CustomTable, { editAction, removeAction, seeLogsAction, RowActions } from "../../../../components/CustomTable";
import CoverService from "../../../../services/Cover";
import useUser from "../../../../hooks/user";
import { Role } from "../../../../models/User";
import CoverDialog from "../../dialogs/CoopManager.cover.dialog";
import Cover from "../../../../models/Cover";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";

interface ContentCovers {
  id: number,
  amount: string,
  provinceName: string,
  actions: JSX.Element
};

interface Props {
  policyId: number,
}
function PolicyDetailsCoversPanel({ policyId }: Props) {
  const { user } = useUser();
  const navigate = useNavigate();
  const headersCovers = useMemo(() => [
    { index: 'amount', title: 'Amount of Coverage', transformer: (v: number) => UtilsService.formatCurrency(v) },
    { index: 'provinceName', title: 'Province Covered' },
    { index: 'actions', title: 'Actions' },
  ], []);

  const [selectedCover, setSelectedCover] = useState<Cover>();
  const [isCoverDialogOpened, toogleCoverDialog] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  const editCoverAction = useCallback((cover: Cover) => {
    setSelectedCover(cover);
  }, []);

  const removeCoverAction = useCallback((coverId: number) => {
    confirmAlert({
      title: 'Confirm delete',
      message: 'Are you sure you want to delete this cover ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => CoverService.deleteCover(coverId)
            .then(res => {
              setReloadData((reload) => !reload);
            })
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  }, []);

  const seeCoverLogsAction = useCallback((coverId: number) => {
    navigate(`/admin/logs?meta=cover-${coverId}`);
  }, [navigate]);

  useEffect(() => {
    if (selectedCover) {
      toogleCoverDialog(true);
    }
  }, [selectedCover]);

  const updateCovers = useCallback((pageNb: number, pageSize: number, filter: string) => {
    return CoverService.getPaginatedCovers(pageSize, pageNb, filter, policyId).then(res => {
      const contents: ContentCovers[] = res.values.map(i => (
        {
          id: i.id,
          amount: i.amount,
          provinceName: i.province.name,
          actions: <RowActions elementId={i.id} actions={[
            editAction(() => editCoverAction(i), user.role === Role.Insuree),
            ...user.role === Role.Admin ? [
              seeLogsAction(() => seeCoverLogsAction(i.id), false),
              removeAction(() => removeCoverAction(i.id), false)
            ] : [],
          ]} />
        }
      ));
      return { total: res.count, rows: contents };
    });
  }, [editCoverAction, policyId, removeCoverAction, seeCoverLogsAction, user.role]);

  return (
    <>
      <CustomTable
        headers={headersCovers}
        updateFunction={updateCovers}
        reloadData={reloadData}
        pageSizeOptions={[5, 10, 15]}
        searchLabel="Province name"
        addButtonAction={user.role === Role.Insuree ? undefined : () => toogleCoverDialog(true)}
        addButtonLabel="Add Cover"
        addButtonDisabled={user.role === Role.Insuree}
        noDataText="No covers."
      />
      <CoverDialog
        cover={selectedCover}
        coopId={policyId}
        opened={isCoverDialogOpened}
        onConfirm={() => {
          toogleCoverDialog(false);
          setSelectedCover(undefined);
          setReloadData(reload => !reload);
        }}
        onClose={() => {
          toogleCoverDialog(false);
          setSelectedCover(undefined);
        }}
      />
    </>
  )
}

export default PolicyDetailsCoversPanel;