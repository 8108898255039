import { useState } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import UserService from '../services/User';
import _envConfig from '../configurations/env.json';
import { Env } from '../configurations/env';

interface FormData {
  password: string;
}

function RenewPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<String | null>(null);
  const { register, formState: { errors }, handleSubmit } = useForm<FormData>();

  const env: Env = (process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'dev') as Env;
  const envConfig = _envConfig.themes.filter(t => t.envs.includes(env))[0];

  const onSubmit = ({ password }: FormData) => {
    setError(null);
    setLoading(true);
    UserService
      .renewPassword(token ?? '', password)
      .then(() => {
        setLoading(false);
        navigate('/login/PASSWORD_SET_OK');
      })
      .catch(() => {
        setLoading(false);
        navigate('/login/PASSWORD_SET_KO');
      });
  };

  return (
    <Container maxWidth="sm">
      <Box mt={3} display="flex" flexDirection="column" alignItems="center">
        <img
          alt="Rainfall Platform"
          src={envConfig.logo_on_light}
          style={{ width: '50%' }}
        />
        <Box mt={1} style={{ width: '100%' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              autoComplete="current-password"
              variant="outlined"
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              {...register('password', {
                required: 'This field is required',
                minLength: { value: 10, message: 'Password mut be at least 10 characters long' }
              })}
            />
            {error !== null && (
              <Box>
                <Typography color="error">
                  {error}
                </Typography>
              </Box>
            )}
            <Box mt={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Send New Password
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Container>
  );
}

export default RenewPassword;