import { useContext } from 'react';

import UserContext from '../components/UserContext';
import { LoginResponse } from '../services/Authenticate';
import ApiService from '../services/Api';
import UserService from '../services/User';

function useAuth() {
  const {
    isAuthenticated,
    isAdmin,
    isSuperUser,
    setUser,
    selectInsureeId,
  } = useContext(UserContext);

  const onLogin: (r: LoginResponse) => Promise<void> = ({ accessToken, refreshToken, expiration, }: LoginResponse) => {
    ApiService.setTokens(accessToken, refreshToken, expiration);
    return UserService
      .me()
      .then((user) => {
        setUser(user);
        if(user.insuree) {
          selectInsureeId(user.insuree.id);
        }
      });
  };

  return {
    isAuthenticated,
    isAdmin,
    isSuperUser,

    onLogin,
  };
}

export default useAuth;