import { useState, useEffect } from 'react';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';

import Province from '../models/Province';
import ProvinceService from '../services/Province';
import useUser from '../hooks/user';

const ProvinceSelector = () => {
  const { selectedProvinceId, selectProvinceId } = useUser();
  const [provinces, setProvinces] = useState<Province[]>([]);

  useEffect(() => {
    ProvinceService
      .getAllProvinces()
      .then((provinces) => {
        setProvinces(provinces.sort((a, b) => a.name.localeCompare(b.name)));
        if(!selectedProvinceId && provinces.length > 0) {
          selectProvinceId(provinces[0].id);
        }
      });
  }, [selectProvinceId, selectedProvinceId]);

  return (
    <Box mb={3} maxWidth="300px">
      <FormControl variant="standard" fullWidth>
        <InputLabel id="provinceSelector">Provinces</InputLabel>
        <Select
          labelId="provinceSelector"
          id="provinceSelector"
          value={selectedProvinceId || -1}
          autoWidth
          onChange={(evt: any) => selectProvinceId(evt.target.value)}
          label="Provinces"
        >
          {provinces.map((province) => (
            <MenuItem key={province.id} value={province.id}>{province.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default ProvinceSelector;
