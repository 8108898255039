import ApiService from './Api';

class WeatherFiles {
  getCurrentFileByCondition(
    weatherConditionId: number
  ):Promise<any> {
    return ApiService.getBinary(`/weatherFiles/current?weatherConditionId=${weatherConditionId}`);
  }  
}

export default new WeatherFiles();