import Cover from "./Cover";
import Insuree from "./Insuree";
import Product from "./Product";

export enum PolicyStatus {
  pending = 'pending',
  inForce = 'in force',
}

interface Policy {
  id: number;
  insuree: Insuree;
  covers: Cover[];
  product: Product;
  status: PolicyStatus;
  start: Date;
  end: Date;
}

export default Policy;