import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Cover from "../../../models/Cover";
import CoverService from "../../../services/Cover";
import Province from "../../../models/Province";
import ProvinceService from '../../../services/Province';
import payoutConfig from '../../../configurations/payouts.json';

interface CoverForm {
  province: number | '',
  amount: number | '',
}

const emptyCoverForm: CoverForm = {
  province: '',
  amount: '',
}

interface Props {
  cover?: Cover;
  coopId: number;
  onConfirm: () => void;
  onClose: Function;
  opened: boolean
}

function CoverDialog({
  cover,
  coopId,
  onClose: _onClose,
  onConfirm: _onConfirm,
  opened,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const { register, control, formState: { errors, isValid }, handleSubmit, reset } = useForm<CoverForm>({ mode: "onChange" });

  const onClose = useCallback(() => {
    _onClose();
    reset(emptyCoverForm);
  }, [_onClose, reset]);

  const onConfirm = useCallback(() => {
    _onConfirm();
    reset(emptyCoverForm);
  }, [_onConfirm, reset]);

  useEffect(() => {
    ProvinceService.getAllProvinces().then(
      res => { setProvinces(res.sort((a, b) => a.name.localeCompare(b.name))) },
      err => { console.log(err) }
    );
  }, []);

  useEffect(() => {
    if (cover) {
      reset({
        province: cover.province.id,
        amount: parseFloat(cover.amount),
      })
    } else {
      reset(emptyCoverForm);
    }
  }, [cover, reset]);

  const onSubmit = (data: CoverForm) => {
    setLoading(true);
    if (cover) {
      CoverService.updateCover({
        id: cover.id,
        province: data.province === "" ? 0 : data.province,
        amount: data.amount === "" ? 0 : data.amount,
      })
        .then(onConfirm)
        .catch(console.error)
        .finally(() => setLoading(false))
    } else {
      CoverService.createCover({
        policy: coopId,
        province: data.province === "" ? 0 : data.province,
        amount: data.amount === "" ? 0 : data.amount,
        red_payout: payoutConfig.red_payout,
        orange_payout: payoutConfig.orange_payout,
        yellow_payout: payoutConfig.yellow_payout,
      })
        .then(onConfirm)
        .catch(console.error)
        .finally(() => setLoading(false))
    }
  };

  return (
    <Dialog open={opened}>
      <DialogTitle>
        {cover ? "Edit cover" : "Create new cover"}
      </DialogTitle>
      <DialogContent>
        <form id="coverForm" onSubmit={handleSubmit(onSubmit)}>
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel>
              Province
            </InputLabel>
            <Controller
              control={control}
              name="province"
              defaultValue={''}
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <Select
                  onChange={field.onChange}
                  value={field.value}
                  error={Boolean(errors.province)}
                  label="Province"
                >
                  {provinces.map((prov, index) => <MenuItem key={index} value={prov.id}>{prov.name}</MenuItem>)}
                </Select>
              )}
            />
            {errors.province && errors.province.message && (
              <FormHelperText error>
                {errors.province.message}
              </FormHelperText>
            )}
          </FormControl>
          <TextField
            label='Coverage'
            fullWidth
            type='Number'
            defaultValue=''
            {...register(
              "amount",
              {
                required: "This field is required",
                min: {
                  message: "Can not be less than 0.",
                  value: 0
                }
              }
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose()}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          form="coverForm"
          color="primary"
          variant="contained"
          disabled={loading || !isValid}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default CoverDialog;