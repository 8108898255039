import ApiService from './Api';
import ProductModel from '../models/Product';

class Product {

  getPaginatedProducts(
    pageSize: number,
    pageNb: number,
  ): Promise<[ProductModel[], number]> {
    return ApiService.get(`/products/paginated?pageSize=${pageSize}&pageNb=${pageNb}`);
  }
}

export default new Product();
