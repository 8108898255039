import ApiService from "./Api";

class Recommendations {
  getAllRecommendationOptions(): Promise<{
    crops: string[];
    types: string[];
    languages: string[];
  }> {
    return ApiService.get(`/recommendations/options`);
  }

  getCropProvinces(crop: string): Promise<string[]> {
    return ApiService.get(`/recommendations/${crop}/provinces`);
  }

  getRecommendation(
    crop: string,
    type: string,
    language: string,
    provinceName?: string
  ): Promise<{
    file: ArrayBuffer;
    filename: any;
  }> {
    return ApiService.getBinaryWithFileName(
      `/recommendations/search?crop=${crop}&type=${type}&language=${language}${
        provinceName ? `&provinceName=${provinceName}` : ""
      }`
    );
  }
}

export default new Recommendations();
