import { ReactElement, useCallback } from "react";

import Drawer from "./Drawer";
import UserTag from "./UserTag";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";

interface LayoutProps {
  children: ReactElement;
}

function Layout({ children }: LayoutProps) {
  const location = useLocation();

  const showUserTag = useCallback(() => {
    switch (location.pathname) {
      case `/dashboard`:
        return false;
      case `/policy-registration`:
        return false;
      default:
        return true;
    }
  }, [location.pathname]);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        height: "100%",
        minHeight: "100vh",
        backgroundColor: "background.default",
      }}
    >
      <Drawer />
      {showUserTag() && (
        <Box
          sx={{
            position: "absolute",
            zIndex: 1,
            top: 1,
            right: 1,
          }}
        >
          <UserTag />
        </Box>
      )}
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default Layout;
