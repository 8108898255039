import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Box,
  Typography,
  debounce
} from '@mui/material';
import { withStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import UtilsService from '../../services/Utils';

import TriggerDot from '../../components/TriggerDot';
import { Fragment, ReactElement, useCallback, useEffect, useState } from 'react';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.default,
    border: 'solid 1px #e0e0e0',
    fontWeight: 'bold'
  },
}))(TableCell);

interface CustomTableProps {
  title: string;
  headers: any[];
  content: any[];
  total: number;
  pageSize: number;
  pageNb: number;
  onChangePage: Function;
  onChangeRowsPerPage: Function;
  onChangeSearch?: Function;
  addFunction?: Function;
  editFunction?: Function;
  removeFunction?: Function;
  additionalActions?: { icon: ReactElement, handler: Function }[];
}

function CustomAdminTable({
  title,
  headers,
  content,
  total,
  pageSize,
  pageNb,
  onChangePage,
  onChangeRowsPerPage,
  onChangeSearch,
  addFunction,
  editFunction,
  removeFunction,
  additionalActions,
}: CustomTableProps) {
  const [search, setSearch] = useState('');

  const changeSearch = useCallback(
    debounce((name: string) => {
      if(onChangeSearch) {
        onChangeSearch(name);
      }
    }, 200),
    []
  );

  useEffect(() => {
    changeSearch(search);
  }, [changeSearch, search])

  const handleChangePage = (event: unknown, newPage: number) => {
    onChangePage(pageSize, newPage);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeRowsPerPage(+event.target.value);
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    onChangePage(pageSize, 0);
  }

  return (
    <Paper sx={{ m: 2, p: 2 }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mb: 2,
      }}>
        <Typography variant='h5'>{title}</Typography>
        {addFunction &&
          <Button
            startIcon={<AddIcon />}
            variant='contained' color='secondary'
            onClick={() => { addFunction() }}
          >
            Add
          </Button>
        }
      </Box>
      {onChangeSearch &&
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          pb: 2,
        }}>
          <TextField
            size="small"
            label="Search"
            variant="outlined"
            color="secondary"
            value={search}
            onChange={handleChangeSearch}
          />
        </Box>
      }
      <TableContainer sx={{
        border: theme => `solid 1px ${theme.palette.grey[300]}`,
        borderRadius: theme => theme.spacing(0.5),
      }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => {
                if (headers.filter(h => h.subHeaders !== undefined).length > 0) {
                  return (
                    <StyledTableCell
                      key={header.index}
                      align="center"
                      rowSpan={header.subHeaders !== undefined ? 1 : 2}
                      colSpan={header.subHeaders !== undefined ? header.subHeaders.length : 1}
                    >
                      {header.title}
                    </StyledTableCell>
                  )
                } else {
                  return (<StyledTableCell key={header.index} align="center">{header.title}</StyledTableCell>)
                }
              })}
              {editFunction && removeFunction && headers.filter(h => h.subHeaders !== undefined).length > 0 && (
                <StyledTableCell align="center" rowSpan={2}>Action</StyledTableCell>
              )}
              {editFunction && removeFunction && headers.filter(h => h.subHeaders !== undefined).length === 0 && (
                <StyledTableCell align="center">Action</StyledTableCell>
              )}
            </TableRow>
            {headers.filter(h => h.subHeaders !== undefined).length > 0 && (
              <TableRow>
                {headers.filter(h => h.subHeaders !== undefined).map((header) => (
                  header.subHeaders.map((sh: { index: string, title: string }) => {
                    switch (sh.title) {
                      case 'Yellow':
                        return (
                          <StyledTableCell key={sh.index} align="center"><TriggerDot level={0} />{sh.title}</StyledTableCell>
                        )
                      case 'Orange':
                        return (
                          <StyledTableCell key={sh.index} align="center"><TriggerDot level={1} />{sh.title}</StyledTableCell>
                        )
                      case 'Red':
                        return (
                          <StyledTableCell key={sh.index} align="center"><TriggerDot level={2} />{sh.title}</StyledTableCell>
                        )
                      default:
                        return (
                          <StyledTableCell key={sh.index} align="center">{sh.title}</StyledTableCell>
                        )
                    }
                  })))}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {content.map((row, index) => (
              <Fragment key={index}>
                <TableRow>
                  {headers.map((header) => {
                    if (header.title === "Trigger") {
                      return (
                        <Fragment key={header.index}>
                          <TableCell align='center'>Rain</TableCell>
                          <TableCell align='center'>{UtilsService.formatPrecipitation(row[header.index]['rain']['yellow_trigger'])}</TableCell>
                          <TableCell align='center'>{UtilsService.formatPrecipitation(row[header.index]['rain']['orange_trigger'])}</TableCell>
                          <TableCell align='center'>{UtilsService.formatPrecipitation(row[header.index]['rain']['red_trigger'])}</TableCell>
                        </Fragment>
                      )
                    } else {
                      if (header.subHeaders !== undefined) {
                        return header.subHeaders.map((sh: { index: string, title: string, transformer: Function }) => {
                          if (sh.transformer) {
                            return (
                              <TableCell key={sh.index} align="center" rowSpan={2}>{sh.transformer(row[header.index][sh.index])}</TableCell>
                            )
                          } else {
                            return (
                              <TableCell key={sh.index} align="center" rowSpan={2}>{row[header.index][sh.index]}</TableCell>
                            )
                          }
                        })
                      } else {
                        if (header.transformer) {
                          return (
                            <TableCell key={header.index} align="center" rowSpan={2}>{header.transformer(row[header.index])}</TableCell>
                          )
                        } else {
                          return (
                            <TableCell key={header.index} align="center" rowSpan={2}>{row[header.index]}</TableCell>
                          )
                        }
                      }
                    }
                  })}
                  {editFunction && removeFunction &&
                    <TableCell align="center" rowSpan={2}>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly'
                      }}>
                        <IconButton
                          sx={{ p: 0.5 }}
                          color="secondary"
                          aria-label="edit"
                          onClick={() => { editFunction(row) }}
                          size="large">
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          sx={{
                            p: 0.5,
                            color: '#CD3D3D',
                            '&:hover': {
                              color: '#AD1F1F',
                            }
                          }}
                          aria-label="delete"
                          onClick={() => { removeFunction(row.id) }}
                          size="large">
                          <DeleteIcon />
                        </IconButton>
                        <>
                          {additionalActions && additionalActions.map((action, index) => (
                            <IconButton
                              key={index}
                              sx={{ p: 0.5 }}
                              onClick={() => { action.handler(row) }}
                              size="large">
                              {action.icon}
                            </IconButton>
                          ))}
                        </>
                      </Box>
                    </TableCell>
                  }
                </TableRow>
                <TableRow>
                  {headers.map((header, index) => {
                    if (header.title === "Trigger") {
                      return (
                        <Fragment key={header.index}>
                          <TableCell align='center'>Wind</TableCell>
                          <TableCell align='center'>{UtilsService.formatWindSpeed(row[header.index]['wind']['yellow_trigger'])}</TableCell>
                          <TableCell align='center'>{UtilsService.formatWindSpeed(row[header.index]['wind']['orange_trigger'])}</TableCell>
                          <TableCell align='center'>{UtilsService.formatWindSpeed(row[header.index]['wind']['red_trigger'])}</TableCell>
                        </Fragment>
                      )
                    } else {
                      return <Fragment key={index} />
                    }
                  })}
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                page={pageNb}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default CustomAdminTable;