import { useState, useEffect } from 'react';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';

import Insuree from '../models/Insuree';
import InsureeService from '../services/Insuree';
import useUser from '../hooks/user';

const InsureeSelector = () => {
  const { selectedInsureeId, selectInsureeId } = useUser();
  const [insurees, setInsurees] = useState<Insuree[]>([]);

  useEffect(() => {
    InsureeService
      .getAllInsurees(false, false)
      .then((insurees) => {
        setInsurees(insurees.sort((a, b) => a.name.localeCompare(b.name)));
        if(!selectedInsureeId && insurees.length > 0) {
          selectInsureeId(insurees[0].id);
        }
      });
  }, [selectInsureeId, selectedInsureeId]);

  return (
    <Box mb={3} maxWidth="300px">
      <FormControl variant="standard" fullWidth>
        <InputLabel id="insureeSelector">Cooperatives</InputLabel>
        <Select
          labelId="insureeSelector"
          id="insureeSelector"
          value={selectedInsureeId || -1}
          autoWidth
          onChange={(evt: any) => selectInsureeId(evt.target.value)}
          label="Cooperatives"
        >
          {insurees.map((insuree) => (
            <MenuItem key={insuree.id} value={insuree.id}>{insuree.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default InsureeSelector;
