import { useState, createContext, ReactElement } from 'react';

import User, { Role } from '../models/User';

interface Context {
  user: User | null;
  selectedInsureeId: number | null;
  selectedProvinceId: number | null;
  isAuthenticated: boolean;
  isSuperUser: boolean;
  isAdmin: boolean;

  setUser: (user: User) => void;
  selectInsureeId: (id: number) => void;
  selectProvinceId: (id: number) => void;
}

const UserContext = createContext({ } as Context);

interface UserContextProviderProps {
  initialUser: User | null;
  children: ReactElement | ReactElement[];
}

function UserContextProvider({ initialUser, children }: UserContextProviderProps) {
  const [user, setUser] = useState<User | null>(initialUser);
  const [insureeId, setInsureeId] = useState<number | null>(user?.insuree?.id || null);
  const [provinceId, setProvinceId] = useState<number | null>(null);

  const context: Context = {
    user,
    selectedInsureeId: insureeId,
    selectedProvinceId: provinceId,
    isAuthenticated: user !== null,
    isSuperUser: user?.role === Role.SuperUser,
    isAdmin: user?.role === Role.Admin,
    setUser,
    selectInsureeId: setInsureeId,
    selectProvinceId: setProvinceId,
  };

  return (
    <UserContext.Provider value={context}>
      { children }
    </UserContext.Provider>
  );
};

export default UserContext;
export { UserContextProvider };
