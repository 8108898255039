import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import Page from "../../../components/Page";
import Insuree from "../../../models/Insuree";
import InsureeService from "../../../services/Insuree";
import CoopDetailsPoliciesPanel from "./CoopDetails.policies.panel";
import CoopDetailsUsersPanel from "./CoopDetails.users.panel";

function CoopEditor() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [coop, setCoop] = useState<Insuree>();

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = useCallback((event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  }, []);

  useEffect(() => {
    const coopId = parseInt(id ?? '0');
    if (!isNaN(coopId)) {
      InsureeService.getById(coopId).then(setCoop);
    }
  }, [id]);

  const a11yProps = useCallback((index: any) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }, []);

  return (
    <Page title="Cooperative Manager">
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          variant="contained"
          sx={{ mr: 3 }}
          startIcon={<BackIcon />}
          onClick={() => navigate("/coopManager")}
        >
          Back
        </Button>
        {coop && (<Typography variant="h5">{coop.name}</Typography>)}
      </Box>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label='Policies' {...a11yProps(0)} />
        <Tab label='Users' {...a11yProps(1)} />
      </Tabs>
      <Box>
        {coop && (
          <>
            <Box sx={{ m: -2 }} hidden={tabValue !== 0}>
              <CoopDetailsPoliciesPanel coopId={coop.id} />
            </Box>
            <Box sx={{ m: -2 }} hidden={tabValue !== 1}>
              <CoopDetailsUsersPanel coopId={coop.id} />
            </Box>
          </>
        )}
      </Box>
    </Page>
  )
}

export default CoopEditor;