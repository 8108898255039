import _envConfig from './configurations/env.json';
import { Env } from './configurations/env';
import { createTheme } from '@mui/material/styles';

const env: Env = (process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'dev') as Env;
const envConfig = _envConfig.themes.filter(t => t.envs.includes(env))[0];

const theme = createTheme({
  palette: {
    primary: {
      main: envConfig.colors.primary.main,
      dark: envConfig.colors.primary.dark,
    },
    secondary: {
      main: envConfig.colors.secondary.main,
      light: envConfig.colors.secondary.light,
      contrastText: envConfig.colors.secondary.contrastText
    },
    background: {
      default: '#F6F6F8',
      paper: '#FFFFFF'
    }
  },
  typography: {
    fontFamily: '\'Poppins\', sans-serif;',
  },
});

export default theme;
