import {
  Box,
  SxProps,
  Theme,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import PastIcon from "@mui/icons-material/History";
import PresentIcon from "@mui/icons-material/QueryBuilder";
import FuturIcon from "@mui/icons-material/Update";
import { useEffect, useState } from 'react';

export enum TemporalityEnum {
  past = 'past',
  current = 'current',
  futur = 'futur',
}

interface Props {
  defaultValue?: TemporalityEnum,
  onChange: (value: TemporalityEnum) => void,
  color?: "primary" | "secondary",
  sx?: SxProps<Theme> | undefined,
}

function TemporalityToggleButtons({ defaultValue, onChange, color, sx }: Props) {
  const [selectedTempo, setSelectedTempo] = useState<TemporalityEnum>(defaultValue ?? TemporalityEnum.current);

  useEffect(() => {
    onChange(selectedTempo);
  }, [onChange, selectedTempo]);

  return (
    <Box sx={{
      m: theme => theme.spacing(0, 2),
      backgroundColor: 'background.paper',
      borderRadius: '3px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      ...sx
    }}>
      <ToggleButtonGroup color={color} sx={{ width: "100%", height: "100%" }} value={selectedTempo}>
        <ToggleButton sx={{ width: "100%", height: "100%" }} value={TemporalityEnum.past} onClick={() => setSelectedTempo(TemporalityEnum.past)}><PastIcon/></ToggleButton>
        <ToggleButton sx={{ width: "100%", height: "100%" }} value={TemporalityEnum.current} onClick={() => setSelectedTempo(TemporalityEnum.current)}><PresentIcon/></ToggleButton>
        <ToggleButton sx={{ width: "100%", height: "100%" }} value={TemporalityEnum.futur} onClick={() => setSelectedTempo(TemporalityEnum.futur)}><FuturIcon/></ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}

export default TemporalityToggleButtons;