import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  TablePagination,
  TextField,
  debounce,
  Box,
  Button,
  IconButton,
  IconButtonPropsColorOverrides,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DetailIcon from '@mui/icons-material/FindInPage';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import TriggerDot from '../components/TriggerDot';
import { withStyles } from '@mui/styles';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.default,
    border: 'solid 1px #e0e0e0',
    fontWeight: 'bold'
  },
}))(TableCell);

export interface RowButtonAction {
  click: () => void;
  disabled: boolean;
  icon: JSX.Element;
  color?: OverridableStringUnion<
    'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    IconButtonPropsColorOverrides
  >;
  tooltip: string;
};

export const detailAction = (click: () => void, disabled: boolean): RowButtonAction => ({
  click,
  disabled,
  icon: <DetailIcon />,
  color: "primary",
  tooltip: "See details",
});

export const editAction = (click: () => void, disabled: boolean): RowButtonAction => ({
  click,
  disabled,
  icon: <EditIcon />,
  color: "secondary",
  tooltip: "Edit item",
});

export const removeAction = (click: () => void, disabled: boolean): RowButtonAction => ({
  click,
  disabled,
  icon: <DeleteIcon />,
  color: "error",
  tooltip: "Delete item",
});

export const seeLogsAction = (click: () => void, disabled: boolean): RowButtonAction => ({
  click,
  disabled,
  icon: <ManageSearchIcon />,
  color: "primary",
  tooltip: "See logs",
});

interface RowActionsProps {
  elementId: number,
  actions: RowButtonAction[],
}
export function RowActions({
  elementId,
  actions,
}: RowActionsProps) {
  const [loading, setLoading] = useState(false);
  const onClick = useCallback((callback: () => void) => async () => {
    setLoading(true);
    await callback();
    setLoading(false);
  }, []);
  return (
    <Box>
      {actions.map((action, index) => (
        <Tooltip title={action.tooltip} key={index}>
          <span>
            <IconButton
              color={action.color}
              onClick={onClick(action.click)}
              disabled={action.disabled || loading}
              size="large">
              {loading ? <CircularProgress/> : action.icon}
            </IconButton>
          </span>
        </Tooltip>
      ))}
    </Box>
  );
}

interface CustomTableProps {
  headers: any[];
  updateFunction: (pageNb: number, pageSize: number, filter: string) => Promise<{ total: number, rows: any[] }>;
  reloadData?: boolean;
  pageSizeOptions?: number[];
  disablePagination?: boolean;
  disableSearch?: boolean;
  searchLabel?: string;
  addButtonElement?: JSX.Element;
  addButtonAction?: Function;
  addButtonLabel?: string;
  addButtonDisabled?: boolean;
  noDataText?: string;
}

function CustomTable({
  headers,
  updateFunction,
  reloadData,
  pageSizeOptions = [10, 25, 50, 100],
  disablePagination = false,
  disableSearch = false,
  searchLabel,
  addButtonElement,
  addButtonAction,
  addButtonLabel,
  addButtonDisabled,
  noDataText,
}: CustomTableProps) {
  const [rows, setRows] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [pageNb, setPageNb] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(pageSizeOptions[0]);
  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState('');

  const [hasSubHeaders, setHasSubHeaders] = useState(false);

  useEffect(() => {
    setHasSubHeaders(headers.filter(h => h.subHeaders !== undefined).length > 0);
  }, [headers]);

  const rowSpan = useCallback((row) => {
    let _maxLines = 1;
    for (const [key, value] of Object.entries(row)) {
      if (Array.isArray(value)) {
        _maxLines = Math.max(_maxLines, value.length);
      }
    }
    return {
      rowSpan: _maxLines,
    }
  }, []);

  const changeSearch = useCallback(
    debounce((name: string) => {
      setFilter(name);
    }, 400),
    []
  );

  useEffect(() => {
    changeSearch(search);
  }, [changeSearch, search]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNb(newPage);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPageNb(0);
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPageNb(0);
  }

  useEffect(() => {
    updateFunction(pageNb, pageSize, filter).then(res => {
      setTotal(res.total);
      setRows(res.rows);
    })
  }, [pageNb, pageSize, filter, updateFunction, reloadData]);

  return (
    <Paper sx={{ m: 2, p: 2 }}>
      {(!disableSearch || addButtonAction !== undefined || addButtonElement !== undefined) && (
        <Box sx={{ display: 'flex', pb: 2 }}>
          {(!disableSearch) && (
            <Box>
              <TextField
                size="small"
                label={searchLabel ?? "Search"}
                variant="outlined"
                color="secondary"
                onChange={handleChangeSearch}
              />
            </Box>
          )}
          {(addButtonAction !== undefined && addButtonElement === undefined) && (
            <Box style={{ marginLeft: 'auto' }}>
              <Button
                variant='contained'
                color='primary'
                startIcon={<AddIcon />}
                onClick={() => addButtonAction()}
                disabled={addButtonDisabled}
              >
                {addButtonLabel ?? 'Add'}
              </Button>
            </Box>
          )}
          {(addButtonAction === undefined && addButtonElement !== undefined) && (
            <Box style={{ marginLeft: 'auto' }}>
              {addButtonElement}
            </Box>
          )}
        </Box>
      )}
      <TableContainer sx={{ border: theme => `solid 1px ${theme.palette.grey[300]}`, borderRadius: theme => theme.spacing(0.5), }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <StyledTableCell
                  key={header.index}
                  align="center"
                  {...hasSubHeaders
                    ? {
                        rowSpan: header.subHeaders !== undefined ? 1 : 2,
                        colSpan: header.subHeaders !== undefined ? header.subHeaders.length : 1,
                      }
                    : {}
                }
                >
                  {header.title}
                </StyledTableCell>
              ))}
            </TableRow>
            {hasSubHeaders && (
              <TableRow>
                {headers.filter(h => h.subHeaders !== undefined).map((header) => (
                  header.subHeaders.map((sh: { index: string, title: string }) => {
                    return <StyledTableCell key={sh.index} align="center">{sh.title}</StyledTableCell>
                  })))}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <Fragment key={index}>
                <TableRow key={index} sx={row['isTotal'] && {
                  backgroundColor: 'background.default',
                  '& > *': {
                    fontWeight: 'bold'
                  }
                }}>
                  {headers.map((header) => {
                    switch (row[header.index]) {
                      case 'yellow_trigger':
                        return (
                          <TableCell key={header.index} align="center" {...rowSpan(row)}><TriggerDot level={0} /></TableCell>
                        )
                      case 'orange_trigger':
                        return (
                          <TableCell key={header.index} align="center" {...rowSpan(row)}><TriggerDot level={1} /></TableCell>
                        )
                      case 'red_trigger':
                        return (
                          <TableCell key={header.index} align="center" {...rowSpan(row)}><TriggerDot level={2} /></TableCell>
                        )
                      default:
                        if (header.subHeaders !== undefined) {
                          return header.subHeaders.map((sh: { index: string, title: string, transformer: Function }) => {
                            if (sh.transformer) {
                              return (
                                <TableCell key={sh.index} align="center">{sh.transformer(Array.isArray(row[header.index]) ? row[header.index][0][sh.index] : row[header.index][sh.index])}</TableCell>
                              )
                            } else {
                              return (
                                <TableCell key={sh.index} align="center">{Array.isArray(row[header.index]) ? row[header.index][0][sh.index] : row[header.index][sh.index]}</TableCell>
                              )
                            }
                          })
                        } else if (header.isLink) {
                          return (
                            <TableCell key={header.index} align="center" {...rowSpan(row)} sx={{ '&>*': { textDecoration: 'none' } }}>
                              <RouterLink to={row[header.index].link} state={row[header.index].state} id={`tableLink${index}`}>
                                {row[header.index].value}
                              </RouterLink>
                            </TableCell>
                          )
                        } else {
                          if (header.transformer) {
                            return (
                              <TableCell key={header.index} align="center" {...rowSpan(row)}>{header.transformer(row[header.index], row['type'])}</TableCell>
                            )
                          } else {
                            return (
                              <TableCell key={header.index} align="center" {...rowSpan(row)}>{row[header.index]}</TableCell>
                            )
                          }
                        }
                    }
                  })}
                </TableRow>
                {hasSubHeaders !== undefined && rowSpan(row).rowSpan > 1 && headers.map((header) => {
                  if (header.subHeaders !== undefined) {
                    return row[header.index].map((subrow: any, subrowIndex: number) => {
                      if (subrowIndex === 0) {
                        return <Fragment key={`${subrowIndex}`}/>
                      } else {
                        return <TableRow key={subrowIndex}>
                          {header.subHeaders.map((sh: { index: string, title: string, transformer: Function }) => {
                            if (sh.transformer) {
                              return (
                                <TableCell key={`subrow-${sh.index}-${subrowIndex}`} align="center">{sh.transformer(subrow[sh.index])}</TableCell>
                              )
                            } else {
                              return (
                                <TableCell key={`subrow-${sh.index}-${subrowIndex}`} align="center">{subrow[sh.index]}</TableCell>
                              )
                            }
                          })}
                        </TableRow>
                      }
                  })
                  } else {
                    return <Fragment key={`subrow-empty-${header.index}`}/>
                  }
                })}
              </Fragment>
            ))}
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={headers.length} sx={{
                  color: 'text.secondary',
                  fontStyle: 'italic',
                  textAlign: 'center'
                }}>
                  {noDataText ? noDataText : "No triggered events."}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {!disablePagination && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={total}
                  page={pageNb}
                  onPageChange={handleChangePage}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={pageSizeOptions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default CustomTable;