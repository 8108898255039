import { Box, Button, Menu, MenuItem } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useRef, useState } from "react";
import PolicyService from '../services/Policy';

interface Props {
    policyId: number;
    callBack: Function;
}

const AddPolicyFileButton = ({ policyId, callBack: callback }: Props) => {
    const [addFileAnchor, setAddFileAnchor] = useState<HTMLElement | null>(null);
    const [fileType, setFileType] = useState<string | null>(null);

    const allFileTypes = PolicyService.getFileTypes();
    const fileRef = useRef<HTMLInputElement>(null);

    const openMenu = (evt: any) => {
      setAddFileAnchor(evt.currentTarget);
    };

    const handleCloseMenu = () => {
      setAddFileAnchor(null);
    }

    const handleMenuClick = (id: string) => {
      setFileType(id);
      if (fileRef && fileRef.current) {
        fileRef.current.click();
      }
    };

    const handleFileChanged = (event: any) => {
      handleCloseMenu();
      const [file] = event.target.files;
      if (fileType !== null) {
        PolicyService
          .addFile(policyId, fileType, file)
          .then(() => {
            // Reset input file
            if (fileRef.current)
              fileRef.current.value = '';
          }).then(() => callback());
      }
    };

    return (
        <Box>
          <Button
            onClick={openMenu}
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
          >
            Add a file
          </Button>
          <Menu
            anchorEl={addFileAnchor}
            keepMounted
            open={Boolean(addFileAnchor)}
            onClose={handleCloseMenu}
          >
            {allFileTypes.map(({ id, label }) => (
              <MenuItem
                key={id}
                onClick={() => handleMenuClick(id)}
              >
                {label}
              </MenuItem>
            ))}
          </Menu>
          <input
            type="file"
            ref={fileRef}
            style={{ display: 'none' }}
            accept="application/pdf"
            onChange={handleFileChanged}
          />
        </Box>
    )
}

export default AddPolicyFileButton;
