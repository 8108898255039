import PolicyModel from "../models/Policy";
import PolicyCreateModel from "../models/creationModels/Policy";
import PolicyUpdateModel from "../models/updateModels/Policy";
import PolicyFile from "../models/PolicyFile";
import PolicyFileUpdateModel from "../models/updateModels/PolicyFile";
import ApiService from './Api';
import CoverCreateModel from '../models/creationModels/Cover';

class Policy {
  getPaginatedPoliciesByInsuree(
    insureeId: number,
    pageSize: number,
    pageNb: number,
  ): Promise<[PolicyModel[], number]> {
    const searchParams = new URLSearchParams(
      {
        insuree: `${insureeId}`,
        pageSize: `${pageSize}`,
        pageNb: `${pageNb}`,
      }
    )
    return ApiService.get(`/policies/paginated?${searchParams.toString()}`);
  }

  getById(id: number): Promise<PolicyModel> {
    return ApiService.get(`/policies/${id}`);
  }

  getPolicyRegistration(insureeId?: number): Promise<PolicyModel[]> {
    return ApiService.get(`/policies/registration${insureeId ? `?insuree=${insureeId}` : ``}`);
  }

  getPreviousPolicyRegistration(insureeId?: number): Promise<PolicyModel[]> {
    return ApiService.get(`/policies/previous-registration${insureeId ? `?insuree=${insureeId}` : ``}`);
  }

  createPolicy(body: PolicyCreateModel): Promise<PolicyModel> {
    return ApiService.post(`/policies`, body);
  }

  registerForPolicy(covers: Omit<CoverCreateModel, "policy">[], insureeId?: number): Promise<PolicyModel> {
    return ApiService.post(`/policies/register${insureeId ? `?insuree=${insureeId}` : ``}`, covers);
  }

  updatePolicy(id: number, body: PolicyUpdateModel): Promise<PolicyModel> {
    return ApiService.patch(`/policies/${id}`, body);
  }

  getFiles(policyId: number): Promise<PolicyFile[]> {
    return ApiService.get(`/policies/${policyId}/documents`);
  }

  getFile(policyId: number, fileId: number) {
    return ApiService.getBinary(`/policies/${policyId}/documents/${fileId}`);
  }

  addFile(policyId: number, type: string, file: File) {
    return ApiService.postBinary(`/policies/${policyId}/documents/${type}`, file);
  }

  deleteFile(policyId: number, fileId: number) {
    return ApiService.delete(`/policies/${policyId}/documents/${fileId}`);
  }

  updateFile(policyId: number, file: PolicyFileUpdateModel) {
    return ApiService.post(`/policies/${policyId}/documents`, file);
  }

  getFileTypes() {
    return [
      {
        id: 'policy-schedule',
        label: 'Policy Schedule',
      },
      {
        id: 'application-form',
        label: 'Application Form',
      }
    ];
  }
}

export default new Policy();
