import { ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, SvgIconTypeMap, SxProps } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Theme } from "@mui/system";
import { useState } from "react";
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { Role } from "../models/User";

export interface MenuItemProps {
    name: string;
    id: string;
    link: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
        muiName: string;
    };
    roles: Role[];
    subMenu?: Omit<MenuItemProps, "roles">[];
}

const listItemStyle: SxProps<Theme> = {
    borderTopLeftRadius: theme => theme.spacing(3),
    borderBottomLeftRadius: theme => theme.spacing(3),
    color: 'white',
    '& svg': {
        fill: 'white',
    },
    '&:hover': {
        backgroundColor: '#0000003A',
    },
    '&.active': {
        backgroundColor: 'background.default',
        color: 'primary.dark',
        borderTopLeftRadius: theme => theme.spacing(3),
        borderBottomLeftRadius: theme => theme.spacing(3),
        '& svg': {
            fill: theme => theme.palette.primary.dark,
        }
    }
}

interface Props extends MenuItemProps {
    drawerOpened: boolean;
}

function DrawerListItem({
    drawerOpened,
    name,
    id,
    link,
    icon: Icon,
    subMenu,
}: Props) {
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {subMenu && <>
                <ListItemButton
                    onClick={handleClick}
                    className={location.pathname.includes(link) ? 'active' : ''}
                    sx={{
                        ...listItemStyle,
                        ...open ? { backgroundColor: '#0000003A' } : {},
                    }}
                >
                    <ListItemIcon><Icon /></ListItemIcon>
                    {drawerOpened && (
                        <ListItemText primary={name} />
                    )}
                </ListItemButton>
                <Menu
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {subMenu.map((menu, index) =>
                        <MenuItem
                            key={index}
                            onClick={handleClose}
                            component={RouterLink}
                            to={menu.link}
                        >
                            {menu.name}
                        </MenuItem>
                    )}
                </Menu>
            </>}
            {
                !subMenu && link && <>
                    <ListItem
                        id={`${id}`}
                        component={RouterLink}
                        to={link}
                        sx={listItemStyle}
                    >
                        <ListItemIcon><Icon /></ListItemIcon>
                        {drawerOpened && (
                            <ListItemText primary={name} />
                        )}
                    </ListItem>
                </>
            }
        </>
    )
}

export default DrawerListItem;