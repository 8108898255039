import { useMap } from "react-leaflet";
import L, { Control } from "leaflet";
import { useEffect, useState } from "react";

interface Props {
  onClick: () => void;
}

const HomeControl = ({ onClick }: Props) => {
  const map = useMap();
  const [clicked, emitClicked] = useState(false); // toggle used to trigger call

  useEffect(() => {
    onClick();
  }, [clicked, onClick]);

  useEffect(() => {
    const HomeControl: Control = L.control.attribution({ position: 'topright' });

    HomeControl.onAdd = () => {
      const button = L.DomUtil.create("button", "leaflet-bar leaflet-control");
      button.style.width = '34px';
      button.style.height = '34px';
      button.style.display = 'flex';
      button.style.justifyContent = 'center';
      button.style.alignItems = 'center';
      button.style.backgroundColor = '#FFFFFF';
      button.style.cursor = 'pointer';
      const img = document.createElement('img');
      img.src = '/icons/home.png';
      button.appendChild(img);
      button.onclick = () => emitClicked(c => !c);
      return button;
    };

    HomeControl.addTo(map);
    return () => { 
      HomeControl.remove();
    };
  }, [map]);

  return null;
};

export default HomeControl;
