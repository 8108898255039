import Page from '../components/Page';
import ChangePassword from '../components/ChangePassword';

function UserProfile() {

  return (
    <Page title="My Profile">
      <ChangePassword />
    </Page>
  );
}

export default UserProfile;