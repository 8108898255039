import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CompPage from "../components/Page";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import RecommendationService from "../services/Recommendation";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import theme from "../theme";
pdfjs.GlobalWorkerOptions.workerSrc = "/workers/pdf.worker.min.js";

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
};

interface FormData {
  crop: string;
  type: string;
  language: string;
  province: string;
  ready: boolean;
}

function Recommendation() {
  const [file, setFile] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [crops, setCrops] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>([]);
  const [languages, setLanguages] = useState<string[]>([]);
  const [provinces, setProvinces] = useState<string[]>(["Global"]);

  const { control, watch, handleSubmit, reset, setValue } = useForm<FormData>({
    defaultValues: { ready: false },
  });

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }
  const changePage = useCallback(
    (offset) =>
      setPageNumber((prevPageNumber) => (prevPageNumber || 1) + offset),
    []
  );
  const previousPage = useCallback(() => changePage(-1), [changePage]);
  const nextPage = useCallback(() => changePage(1), [changePage]);

  const findAndSetProvinceOptionsForCropType = useCallback(
    (crop: string, callback?: (provinces: string[]) => void) => {
      RecommendationService.getCropProvinces(crop).then((provinces) => {
        setProvinces(provinces.length > 0 ? provinces : ["Global"]);
        typeof callback === "function" && callback(provinces);
      });
    },
    []
  );

  const onCropChange = useCallback(
    (field: ControllerRenderProps<FormData, "crop">) =>
      (event: SelectChangeEvent<string>) => {
        setValue("ready", false);
        field.onChange(event);
        findAndSetProvinceOptionsForCropType(
          event.target.value,
          (provinces) => {
            setValue("province", provinces[0]);
            setValue("ready", true);
          }
        );
      },
    [findAndSetProvinceOptionsForCropType, setValue]
  );

  const cropMapper = useCallback((crop: string) => {
    return `${crop.charAt(0).toUpperCase()}${crop.slice(1)}`;
  }, []);

  const languageMapper = useCallback((lang: string) => {
    switch (lang) {
      case "FIL":
        return "Filipino";
      case "CEB":
        return "Cebuano";
      case "ENG":
      default:
        return "English";
    }
  }, []);

  const typeMapper = useCallback((type: string) => {
    switch (type) {
      case "onePage":
        return "Summary";
      case "brochure":
      default:
        return "In depth";
    }
  }, []);

  const onSubmit = (data: FormData) => {
    if (data.ready) {
      RecommendationService.getRecommendation(
        data.crop,
        data.type,
        data.language,
        data.province === "Global" ? undefined : data.province
      )
        .then((fileData) => {
          const blob = new Blob([fileData.file], {
            type: "application/pdf",
          });
          const objectUrl = URL.createObjectURL(blob);
          setFileName(fileData.filename);
          setFile(objectUrl);
        })
        .catch(() => {
          setFileName("");
          setFile(undefined);
        });
    }
  };

  useEffect(() => {
    RecommendationService.getAllRecommendationOptions().then((options) => {
      setCrops(options.crops);
      setTypes(options.types);
      setLanguages(options.languages);
      findAndSetProvinceOptionsForCropType(options.crops[0], (provinces) =>
        reset({
          crop: options.crops[0],
          type: options.types[0],
          language: options.languages[0],
          province: provinces[0],
          ready: true,
        })
      );
    });
  }, [findAndSetProvinceOptionsForCropType, reset]);

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <CompPage title={`Recommendations`}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "1008px", // size of the pdf viewer
          m: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "stretch",
            m: (theme) => theme.spacing(2, 0, 2, 1),
            "&>img": {
              maxHeight: "200px",
              borderRadius: 2,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                padding: "0 1rem 0.5rem 0",
                "& > div": {
                  pl: 3,
                },
              }}
            >
              Here is advice for farmers to improve quality of crops and
              livestock under different farming conditions: <b>normal</b>,{" "}
              <b>wet</b> and <b>dry</b>.
              <br />
              <br />
              Documents that provide advice for a crop in all cycles of the
              plant:
              <Box>• plant varieties and land preparation</Box>
              <Box>• weed and pest management</Box>
              <Box>• harvesting and storage</Box>
              as well as a document that provides advice for livestock.
              <br />
              <br />
              Advice is provided by Alliance of Bioversity International and
              CIAT
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">PDF Viewer - {fileName}</Typography>
                <Box>
                  <Button
                    sx={{ ml: 4 }}
                    variant="contained"
                    color="primary"
                    href={file}
                  >
                    Download
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <img src="/recommendation.png" alt="recommendation" />
        </Box>

        <Paper>
          <form>
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <FormControl
                variant="outlined"
                fullWidth
                sx={{ ml: "1em", mr: "0.5em" }}
              >
                <InputLabel>Type of crop</InputLabel>
                <Controller
                  control={control}
                  name="crop"
                  defaultValue={""}
                  render={({ field }) => (
                    <Select
                      onChange={onCropChange(field)}
                      value={field.value}
                      label="Type of crop"
                    >
                      {crops.map((crop) => (
                        <MenuItem key={crop} value={crop}>
                          {cropMapper(crop)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <FormControl
                variant="outlined"
                fullWidth
                sx={{ ml: "0.5em", mr: "0.5em" }}
              >
                <InputLabel>Type of document</InputLabel>
                <Controller
                  control={control}
                  name="type"
                  defaultValue={""}
                  render={({ field }) => (
                    <Select
                      onChange={field.onChange}
                      value={field.value}
                      label="Type of document"
                    >
                      {types.map((type) => (
                        <MenuItem key={type} value={type}>
                          {typeMapper(type)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <FormControl
                variant="outlined"
                fullWidth
                sx={{ ml: "0.5em", mr: "0.5em" }}
              >
                <InputLabel>Language</InputLabel>
                <Controller
                  control={control}
                  name="language"
                  defaultValue={""}
                  render={({ field }) => (
                    <Select
                      onChange={field.onChange}
                      value={field.value}
                      label="Language"
                    >
                      {languages.map((language) => (
                        <MenuItem key={language} value={language}>
                          {languageMapper(language)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <FormControl
                variant="outlined"
                fullWidth
                sx={{ ml: "0.5em", mr: "1em" }}
              >
                <InputLabel>Province</InputLabel>
                <Controller
                  control={control}
                  name="province"
                  defaultValue={"Global"}
                  render={({ field }) => (
                    <Select
                      onChange={field.onChange}
                      value={field.value}
                      label="Province"
                    >
                      {provinces.map((province) => (
                        <MenuItem key={province} value={province}>
                          {province}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Box>
          </form>
          <Box
            id="pdf-buttons"
            sx={{
              mx: "auto",
              mt: theme.spacing(1),
              height: theme.spacing(4),
              width: theme.spacing(20),
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: (theme) => theme.spacing(0.5),
              overflow: "hidden",
            }}
          >
            <Button
              disabled={pageNumber <= 1}
              onClick={previousPage}
              type="button"
              sx={{
                height: "100%",
                minWidth: theme.spacing(5),
                backgroundColor: "background.paper",
                border: "none",
                "&:hover": {
                  backgroundColor: "background.default",
                  cursor: "pointer",
                },
              }}
            >
              {"<"}
            </Button>
            <Box>
              {`${pageNumber || (numPages ? 1 : "--")} of ${numPages || "--"}`}
            </Box>
            <Button
              disabled={pageNumber >= numPages}
              onClick={nextPage}
              type="button"
              sx={{
                height: "100%",
                minWidth: theme.spacing(5),
                backgroundColor: "background.paper",
                border: "none",
                "&:hover": {
                  backgroundColor: "background.default",
                  cursor: "pointer",
                },
              }}
            >
              {">"}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              m: theme.spacing(1),
              mb: theme.spacing(3),
            }}
          >
            <Box
              sx={{
                overflow: "hidden",
                width: "fit-content",
              }}
            >
              <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
                error="No document available for this combination."
                noData="No document available for this combination."
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </Box>
          </Box>
        </Paper>
      </Box>
    </CompPage>
  );
}

export default Recommendation;
