const BASE_PATH = process.env.REACT_APP_API_BASE_URL;

export class ApiError extends Error {
  statusCode: number;
  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
}

class Api {
  accessToken: string | null = null;
  refreshToken: string | null = null;
  tokenExpiration: number | null = null;

  setTokens(accessToken: string, refreshToken: string, tokenExpiration: Date) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.tokenExpiration = tokenExpiration.getTime();
    this.storeRefreshToken(refreshToken);
  }

  removeTokens() {
    this.accessToken = null;
    this.refreshToken = null;
    this.tokenExpiration = null;
    this.clearRefreshToken();
  }

  get(path: string): Promise<any> {
    return this.fetch("GET", path);
  }

  getBinary(path: string): Promise<ArrayBuffer> {
    return fetch(`${BASE_PATH}${path}`, {
      method: "GET",
      headers: this.getHeaders(),
    }).then(async (response) => response.arrayBuffer());
  }

  getBinaryWithFileName(
    path: string
  ): Promise<{ file: ArrayBuffer; filename: any }> {
    return fetch(`${BASE_PATH}${path}`, {
      method: "GET",
      headers: this.getHeaders(),
    }).then(async (response) => {
      return {
        file: await response.arrayBuffer(),
        filename: response.headers
          .get("content-disposition")!
          .split("=")[1]
          .slice(1, -1),
      };
    });
  }

  post(path: string, body: any): Promise<any> {
    return this.fetch("POST", path, body);
  }

  patch(path: string, body: any): Promise<any> {
    return this.fetch("PATCH", path, body);
  }

  postBinary(path: string, file: File): Promise<any> {
    const data = new FormData();
    data.append("file", file);
    return fetch(`${BASE_PATH}${path}`, {
      method: "POST",
      headers: this.getHeaders(false),
      body: data,
    }).then((res) => {
      if (res.status >= 200 && res.status < 300) return res.json();
      return res.json().then(({ statusCode, message }) => {
        throw new ApiError(message, statusCode);
      });
    });
  }

  put(path: string, body: any): Promise<any> {
    return this.fetch("PUT", path, body);
  }

  delete(path: string): Promise<any> {
    return this.fetch("DELETE", path);
  }

  getStoreRefreshToken(): string | null {
    return window.localStorage.getItem("refreshToken");
  }

  refreshTokens(token: string) {
    return this.fetch(
      "POST",
      "/auth/refresh",
      { refreshToken: token },
      true
    ).then(({ accessToken, refreshToken, expiration }) => {
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
      this.tokenExpiration = new Date(expiration).getTime();
    });
  }

  private async fetch(
    method: string,
    path: string,
    body?: any,
    unauthenticated: boolean = false
  ): Promise<any> {
    if (unauthenticated === false) await this.refreshTokenIfRequired();
    return fetch(`${BASE_PATH}${path}`, {
      method,
      headers: this.getHeaders(true, unauthenticated),
      body: body ? JSON.stringify(body) : null,
    }).then((res) => {
      if (res.status >= 200 && res.status < 300) return res.json();
      return res.json().then(({ statusCode, message }) => {
        throw new ApiError(message, statusCode);
      });
    });
  }

  private refreshTokenIfRequired(): Promise<any> {
    if (
      this.accessToken !== null &&
      this.tokenExpiration !== null &&
      this.tokenExpiration - new Date().getTime() < 10 * 60 * 1000
    ) {
      return this.refreshTokens(this.refreshToken!);
    }
    return Promise.resolve();
  }

  private getHeaders(
    withContentType: boolean = true,
    unauthenticated: boolean = false
  ): Headers {
    const headers = new Headers();
    if (withContentType) headers.append("Content-Type", "application/json");
    if (this.accessToken && !unauthenticated)
      headers.append("Authorization", `Bearer ${this.accessToken}`);
    return headers;
  }

  private storeRefreshToken(token: string) {
    window.localStorage.setItem("refreshToken", token);
  }

  private clearRefreshToken() {
    window.localStorage.removeItem("refreshToken");
  }
}

export default new Api();
