import { Box, Button, Typography } from '@mui/material';
import Page from '../components/Page';
import SingleDataCard from '../components/SingleDataCard';
import CustomTable from '../components/CustomTable';
import ProvinceService from '../services/Province';
import CoverService from '../services/Cover';
import BenefitService from '../services/Benefit';
import UtilsService from '../services/Utils';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';

const headers = [
  { index: 'name', title: 'Name', isLink: true },
  { index: 'coverage', title: 'Coverage', transformer: (v: number) => UtilsService.formatCurrency(v) },
  { index: 'lastTrigger', title: 'Last Trigger' },
  { index: 'lastTriggerDate', title: 'Last Trigger Date', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'lastBenefit', title: 'Last Payout', transformer: (v: number) => UtilsService.formatCurrency(v) },
  { index: 'totalBenefit', title: 'Total Payout', transformer: (v: number) => UtilsService.formatCurrency(v) }
];

interface Content {
  id: number,
  name: { value: string, link: string },
  coverage: number,
  lastTrigger: string,
  lastTriggerDate: string,
  lastBenefit: number,
  totalBenefit: number
};

function OverviewCoop() {
  const { coopId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [coverage, setCoverage] = useState<number>(0);
  const [totalBenefits, setTotalBenefits] = useState<number>(0);

  useEffect(() => {
    CoverService.getTotalCoverageOfInsuree(coopId ?? '0', moment()).then((res) => {
      setCoverage(res.sum ? res.sum : 0);
    },
      err => { console.log(err) }
    );
    BenefitService.getTotalBenefitsValueOfInsuree(coopId ?? '0').then((res) => {
      setTotalBenefits(res.sum ? res.sum : 0);
    },
      err => { console.log(err) }
    );
  }, [coopId]);

  const updateProvinces = useCallback((pageNb: number, pageSize: number, filter: string) => {
    return ProvinceService.getProvincesOfInsuree(coopId ?? '0', pageSize, pageNb, filter).then((res) => {
      const contents: Content[] = res[0].map(p => {
        const lastTriggers = p.covers
          .filter(c => c.benefits.length > 0)
          .map(c => c.benefits.reduce((acc, curr) => acc.createdAt > curr.createdAt ? acc : curr));
        const lastTrigger = lastTriggers.length > 0
          ? lastTriggers.reduce((acc, curr) => acc.createdAt > curr.createdAt ? acc : curr)
          : null;
        const content: Content = {
          id: p.id,
          name: { value: p.name, link: `/dashboard/${p.id}/${coopId}` },
          coverage: p.covers.filter(c => moment(c.policy.start) <= moment() && moment() <= moment(c.policy.end)).reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0),
          lastTrigger: lastTrigger ? lastTrigger.trigger : '-',
          lastTriggerDate: lastTrigger ? lastTrigger.date : '-',
          lastBenefit: lastTrigger ? parseFloat(lastTrigger.amount) : 0,
          totalBenefit: p.covers.map(c => c.benefits.reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0)).reduce((acc, curr) => acc + curr)
        };
        return content;
      });
      return { total: res[1], rows: contents }
    });
  }, [coopId]);

  return (
    <Page title={"Cooperative Overview"}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2, ml: 2 }}>
        <Button
          variant="contained"
          sx={{ mr: 3 }}
          startIcon={<BackIcon />}
          onClick={() => navigate("/overview", { state: { tabNb: 0 } })}
        >
          Back
        </Button>
        {state.coopName && (<Typography variant="h5">{state.coopName}</Typography>)}
      </Box>
      <Box sx={{
        display: 'flex',
        width: '100%',
        '& > *': {
          width: '100%'
        }
      }}>
        <SingleDataCard title={'Coverage'} content={`${UtilsService.formatCurrency(coverage)}`} />
        <SingleDataCard title={'Total Payouts'} content={`${UtilsService.formatCurrency(totalBenefits)}`} />
      </Box>
      <CustomTable
        headers={headers}
        updateFunction={updateProvinces}
        pageSizeOptions={[10, 15, 20]}
      />
    </Page>
  );
}

export default OverviewCoop;