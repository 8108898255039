import { MultiPolygon } from 'geojson';
import { LatLng } from 'leaflet';
import { useEffect, useState } from 'react';
import { Polygon, Tooltip } from 'react-leaflet';

interface Props {
  className: string,
  id: number,
  multiPolygon: MultiPolygon,
  color: string,
  tooltip: string,
  isSelected: boolean,
  onSelect: (id: number) => any
}

function InteractiveMultiPolygon({ className, id, multiPolygon, color, tooltip, isSelected, onSelect }: Props) {
  const [fillOpacity, setFillOpacity] = useState(0.2);

  useEffect(() => {
    isSelected ? setFillOpacity(0.6) : setFillOpacity(0.2);
  }, [isSelected])

  return (
    <Polygon
      className={className}
      pathOptions={{ color, fillOpacity }}
      positions={multiPolygon.coordinates.map((p) => p.map(c => c.map((v) => new LatLng(v[0], v[1]))))}
      eventHandlers={{
        mouseover: () => {
          setFillOpacity(0.4);
        },
        mouseout: () => {
          setFillOpacity(isSelected ? 0.6 : 0.2);
        },
        click: () => {
          onSelect(id);
        },
      }}
    >
      <Tooltip direction='top'>{tooltip}</Tooltip>
    </Polygon>
  );
}

export default InteractiveMultiPolygon;
