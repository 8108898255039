import { useContext } from 'react';
import DrawerContext from '../components/DrawerContext';

function useDrawer() {
  const { drawerOpened, setDrawerOpened } = useContext(DrawerContext);

  return {
    drawerOpened,
    setDrawerOpened
  };
}

export default useDrawer;
