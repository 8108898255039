import { Box } from '@mui/material';

import Page from '../components/Page';
import CustomLogsTable from '../components/admin/CustomLogsTable';
import { useEffect, useState } from 'react';
import LogService from '../services/Logs';
import UtilsService from '../services/Utils';
import '../components/modals/css/react-confirm-alert.css';

const headersLogs = [
  { index: 'timestamp', title: 'Timestamp', transformer: (d: string) => UtilsService.formatTimestamp(d) },
  { index: 'level', title: 'Level' },
  { index: 'message', title: 'Message' },
  { index: 'meta', title: 'Metadata' },
];

interface ContentLogs {
  id: number,
  level: string,
  message: string,
  meta: string,
  timestamp: Date
};

function AdminLogs() {
  const [logs, setLogs] = useState<ContentLogs[]>([]);
  const [logsTotal, setLogsTotal] = useState<number>(0);
  const [logsPageNb, setLogsPageNb] = useState<number>(0);
  const [logsPageSize, setLogsPageSize] = useState<number>(10);
  const [logsMessageFilter, setLogsMessageFilter] = useState<string>('');
  const [logsMetaFilter, setLogsMetaFilter] = useState<string>('');
  const [logsLevelFilter, setLogsLevelFilter] = useState<string>('verbose,debug,info,warning,error');

  useEffect(() => {
    LogService.getLogsPaginated(logsPageSize, logsPageNb, logsMessageFilter, logsLevelFilter, logsMetaFilter).then(res => {
      const contents = res[0].map(i => {
        const content: ContentLogs = {
          id: i.id,
          level: i.level,
          message: i.message,
          meta: i.meta,
          timestamp: i.timestamp
        };
        return content;
      });
      setLogs(contents);
      setLogsTotal(res[1]);
    },
      err => { console.log(err) }
    );
  }, [logsPageSize, logsPageNb, logsMessageFilter, logsLevelFilter, logsMetaFilter]);

  const handleChangePage = (pageSize: number, pageNb: number) => {
    setLogsPageNb(pageNb);
    setLogsPageSize(pageSize);
  };

  const handleChangeRowsPerPage = (pageSize: number) => {
    setLogsPageNb(0);
    setLogsPageSize(pageSize);
  };

  const handleChangeMessageSearch = (search: string) => {
    setLogsPageNb(0);
    setLogsMessageFilter(search);
  };

  const handleChangeMetaSearch = (search: string) => {
    setLogsPageNb(0);
    setLogsMetaFilter(search);
  };

  const handleChangeLogLevel = (level: string) => {
    setLogsPageNb(0);
    setLogsLevelFilter(level);
  };

  return (
    <Page title="Admin">
      <Box sx={{
        display: 'flex',
        '& > *': {
          width: '100%',
          height: 'fit-content'
        }
      }}>
        <CustomLogsTable
          headers={headersLogs}
          content={logs}
          total={logsTotal}
          pageNb={logsPageNb}
          pageSize={logsPageSize}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangeMessageSearch={handleChangeMessageSearch}
          onChangeMetaSearch={handleChangeMetaSearch}
          onChangeLogLevel={handleChangeLogLevel}
        />
      </Box>
    </Page>
  );
}

export default AdminLogs;