import ApiService from './Api';
import InsureeModel from '../models/Insuree';
import InsureeCreationModel from '../models/creationModels/Insuree';
import InsureeUpdateModel from '../models/updateModels/Insuree';
import { PolicyStatus } from '../models/Policy';
import { TemporalityEnum } from '../components/TemporalityToggleButtons';

class Insuree {

  getById(id: number):Promise<InsureeModel> {
    return ApiService.get(`/insurees/${id}`);
  }

  getNumberOfInsurees(
    status?: PolicyStatus,
    temporality?: TemporalityEnum,
  ): Promise<number> {
    const searchParams = new URLSearchParams(
      {
        ...(status ? { status } : {}),
        ...(temporality ? { temporality } : {}),
      }
    )
    return ApiService.get(`/insurees/nb?${searchParams.toString()}`);
  }

  getAllInsurees(
    withCovers: boolean,
    withBenefits: boolean
  ):Promise<InsureeModel[]> {
    return ApiService.get(`/insurees?covers=${withCovers}&benefits=${withBenefits}`);
  }

  getAllInsureesPaginated(
    pageSize: number,
    pageNb: number,
    nameFilter: string,
    status?: PolicyStatus,
    temporality?: TemporalityEnum,
  ):Promise<[InsureeModel[], number]> {
    return ApiService.get(`/insurees/paginated?pageSize=${pageSize}&pageNb=${pageNb}&nameFilter=${nameFilter}${status ? `&status=${status}` : ``}${temporality ? `&temporality=${temporality}` : ``}`);
  }

  createInsuree(insuree: InsureeCreationModel):Promise<InsureeModel> {
    return ApiService.post(`/insurees/create`, insuree);
  }
  
  updateInsuree(insuree: InsureeUpdateModel):Promise<InsureeModel> {
    return ApiService.post(`/insurees/update`, insuree);
  }
  
  deleteInsuree(insureeId: number) {
    return ApiService.delete(`/insurees/delete/${insureeId}`);
  }
  
}

export default new Insuree();
