import { Box, Button, Modal, TextField } from "@mui/material";

import Page from "../components/Page";
import CustomAdminTable from "../components/admin/CustomAdminTable";
import { useCallback, useEffect, useMemo, useState } from "react";
import ProvinceService from "../services/Province";
import ProductService from "../services/Product";
import UtilsService from "../services/Utils";
import AdminSvc from "../services/Admin";
import AddProvince from "../components/modals/AddProvince";
import UsersList from "../components/admin/UsersList";
import MirrorPanel from "../components/admin/MirrorPanel";
import UpdateProvince from "../components/modals/UpdateProvince";
import { confirmAlert } from "react-confirm-alert";
import "../components/modals/css/react-confirm-alert.css";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import wcConfig from "../configurations/weatherConditions.json";

const headersProvinces = [
  { index: "id", title: "#" },
  { index: "province", title: "Province" },
  {
    index: "reinsuranceCapacity",
    title: "Reinsurance Capacity",
    transformer: (v: number) => UtilsService.formatCurrency(v),
  },
  {
    index: "trigger",
    title: "Trigger",
    subHeaders: [
      { index: "type", title: "Type" },
      {
        index: "yellow",
        title: wcConfig.triggers.yellowTrigger.name.split(" ")[0],
      },
      {
        index: "orange",
        title: wcConfig.triggers.orangeTrigger.name.split(" ")[0],
      },
      { index: "red", title: wcConfig.triggers.redTrigger.name.split(" ")[0] },
    ],
  },
];

export interface ContentProvinces {
  id: number;
  province: string;
  trigger: {
    rain: {
      yellow_trigger: number;
      orange_trigger: number;
      red_trigger: number;
    };
    wind: {
      yellow_trigger: number;
      orange_trigger: number;
      red_trigger: number;
    };
  };
  path: string;
  reinsuranceCapacity: number;
}

const headersProducts = [
  { index: "id", title: "#" },
  { index: "name", title: "Product name" },
  {
    index: "prenium",
    title: "Prenium",
    transformer: (v: number) => UtilsService.formatPercentage(v),
  },
];

export interface ContentProducts {
  id: number;
  name: string;
  prenium: string;
}

function Admin() {
  const [provinces, setProvinces] = useState<ContentProvinces[]>([]);
  const [provincesTotal, setProvincesTotal] = useState<number>(0);
  const [provincesPageNb, setProvincesPageNb] = useState<number>(0);
  const [provincesPageSize, setProvincesPageSize] = useState<number>(5);
  const [provincesNameFilter, setProvincesNameFilter] = useState<string>("");
  const [provinceIdToEdit, setProvinceIdToEdit] = useState<number>();
  const [provinceToEdit, setProvinceToEdit] = useState<ContentProvinces>();
  
  const [products, setProducts] = useState<ContentProducts[]>([]);
  const [productsTotal, setProductsTotal] = useState<number>(0);
  const [productsPageNb, setProductsPageNb] = useState<number>(0);
  const [productsPageSize, setProductsPageSize] = useState<number>(5);
  const [productIdToEdit, setProductIdToEdit] = useState<number>();
  const [productToEdit, setProductToEdit] = useState<ContentProducts>();

  const [open, setOpen] = useState(false);
  const [isCoopModalUsed, setIsCoopModalUsed] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [csv, setCsv] = useState<string>();
  const [csvDate, setCsvDate] = useState<moment.Moment | null>(moment());

  useEffect(() => {
    ProvinceService.getAllProvincesPaginated(
      provincesPageSize,
      provincesPageNb,
      provincesNameFilter
    ).then(
      (res) => {
        const contents = res[0].map((p) => {
          const content: ContentProvinces = {
            id: p.id,
            province: p.name,
            trigger: {
              rain: {
                yellow_trigger: p.triggers.find((t) => t.index.name === 'rain')?.yellow ?? 0,
                orange_trigger: p.triggers.find((t) => t.index.name === 'rain')?.orange ?? 0,
                red_trigger: p.triggers.find((t) => t.index.name === 'rain')?.red ?? 0,
              },
              wind: {
                yellow_trigger: p.triggers.find((t) => t.index.name === 'wind')?.yellow ?? 0,
                orange_trigger: p.triggers.find((t) => t.index.name === 'wind')?.orange ?? 0,
                red_trigger: p.triggers.find((t) => t.index.name === 'wind')?.red ?? 0,
              },
            },
            path: p.path ? UtilsService.formatGeometryForAdmin(p.path) : "",
            reinsuranceCapacity: p.reinsuranceCapacity,
          };
          return content;
        });
        setProvinces(contents);
        setProvincesTotal(res[1]);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [provincesPageSize, provincesPageNb, provincesNameFilter, refresh]);

  useEffect(() => {
    ProductService.getPaginatedProducts(productsPageSize, productsPageNb).then((res) => {
      const contents: ContentProducts[] = res[0].map((p) => ({
        id: p.id,
        name: p.name,
        prenium: p.prenium,
      }))
      setProducts(contents);
      setProductsTotal(res[1]);
    },
    (err) => {
      console.log(err);
    });
  }, [productsPageNb, productsPageSize]);

  useEffect(() => {
    setProvinceToEdit(provinces.find((p) => p.id === provinceIdToEdit));
  }, [provinceIdToEdit, provinces]);

  useEffect(() => {
    if (csvDate) {
      AdminSvc.getTriggerConfirmations(csvDate).then((res) => {
        setCsv(res);
      });
    }
  }, [csvDate]);

  const handleOpen = (coop: boolean, add: boolean, objectId?: number) => {
    setIsCoopModalUsed(coop);
    setIsAddModal(add);
    if (objectId && !coop) {
      setProvinceIdToEdit(objectId);
    }
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setRefresh((_refresh) => !_refresh);
    setOpen(false);
  }, []);

  const handleChangePageProv = (pageSize: number, pageNb: number) => {
    setProvincesPageNb(pageNb);
    setProvincesPageSize(pageSize);
  };

  const handleChangeRowsPerPageProv = (pageSize: number) => {
    setProvincesPageNb(0);
    setProvincesPageSize(pageSize);
  };

  const handleChangePageProduct = (pageSize: number, pageNb: number) => {
    setProductsPageNb(pageNb);
    setProductsPageSize(pageSize);
  };

  const handleChangeRowsPerPageProduct = (pageSize: number) => {
    setProductsPageNb(0);
    setProductsPageSize(pageSize);
  };

  const handleChangeSearchProv = (search: string) => {
    setProvincesNameFilter(search);
  };

  const handleDeleteProv = (id: number) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            ProvinceService.deleteProvince(id).then((res) => {
              if (res.status === "ok") {
                setRefresh((ref) => !ref);
              } else {
                handleError(res.error);
              }
            }),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleError = (error: string) => {
    confirmAlert({
      title: "An error occured !",
      message: error,
      buttons: [
        {
          label: "Ok",
          className: "react-confirm-alert-button-error",
          onClick: () => {},
        },
      ],
    });
  };

  const body = useMemo(() => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          backgroundColor: "background.paper",
          padding: (theme) => theme.spacing(2, 4),
          borderRadius: 1,
          maxHeight: "80vh",
        }}
      >
        {isAddModal && (
          <>
            {!isCoopModalUsed && (
              <AddProvince
                handleClose={() => {
                  handleClose();
                }}
              />
            )}
          </>
        )}
        {!isAddModal && (
          <>
            {!isCoopModalUsed && (
              <UpdateProvince
                handleClose={() => {
                  handleClose();
                }}
                provinceToEdit={provinceToEdit}
              />
            )}
          </>
        )}
      </Box>
    );
  }, [isAddModal, isCoopModalUsed, provinceToEdit, handleClose]);

  return (
    <Page title="Admin">
      <Box style={{ margin: "8px 16px", display: "flex" }}>
        <Button
          variant="contained"
          color="secondary"
          href={`data:text/json;charset=utf-8,${encodeURIComponent(csv ?? "")}`}
          download="triggers.csv"
          disabled={!csv}
        >
          Get triggers confirmation since
        </Button>
        <DatePicker
          label="Coverage Date"
          value={csvDate}
          onChange={(v) => setCsvDate(v)}
          renderInput={(params) => <TextField {...params} sx={{ ml: 2 }} />}
        />
      </Box>
      <CustomAdminTable
        title="Provinces"
        headers={headersProvinces}
        content={provinces}
        addFunction={() => {
          handleOpen(false, true);
        }}
        editFunction={(object: ContentProvinces) => {
          handleOpen(false, false, object.id);
        }}
        removeFunction={handleDeleteProv}
        total={provincesTotal}
        pageNb={provincesPageNb}
        pageSize={provincesPageSize}
        onChangePage={handleChangePageProv}
        onChangeRowsPerPage={handleChangeRowsPerPageProv}
        onChangeSearch={handleChangeSearchProv}
      />
      <CustomAdminTable
        title="Products"
        headers={headersProducts}
        content={products}
        total={productsTotal}
        pageNb={productsPageNb}
        pageSize={productsPageSize}
        onChangePage={handleChangePageProduct}
        onChangeRowsPerPage={handleChangeRowsPerPageProduct}
      />
      <Box>
        <UsersList />
      </Box>
      {process.env.REACT_APP_ENV === "prod" ? <MirrorPanel /> : <></>}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </Page>
  );
}

export default Admin;
