import { CssBaseline, ThemeProvider, Box } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import Router from './components/Router';
import { UserContextProvider } from './components/UserContext';
import { DrawerContextProvider } from './components/DrawerContext';
import User from './models/User';
import theme from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers';

interface InitialState {
  user: User | null;
}

function App({ user }: InitialState) {
  return <>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box className="app" sx={{ minHeight: "100%" }}>
          <UserContextProvider initialUser={user}>
            <DrawerContextProvider initialState={true}>
              <Router />
            </DrawerContextProvider>
          </UserContextProvider>
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  </>;
}

export default App;
