import { useCallback, useState } from "react";
import UtilsService from '../../services/Utils';
import Page from "../../components/Page";
import CustomTable, { detailAction, editAction, removeAction, RowActions, seeLogsAction } from "../../components/CustomTable";
import InsureeService from '../../services/Insuree';
import { useNavigate } from "react-router-dom";
import CoopDialog from "./dialogs/CoopManager.coop.dialog";
import useUser from "../../hooks/user";
import { Role } from "../../models/User";
import { confirmAlert } from "react-confirm-alert";
import Insuree from "../../models/Insuree";

const headersCoops = [
  { index: 'coopName', title: 'Cooperative Name' },
  { index: 'totalCoverage', title: 'Total Coverage', transformer: (v: number) => UtilsService.formatCurrency(v) },
  { index: 'lastTrigger', title: 'Last Trigger Date', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'totalBenefits', title: 'Total Payout', transformer: (v: number) => UtilsService.formatCurrency(v) },
  { index: 'actions', title: 'Actions' },
];

interface ContentCoops {
  id: number,
  coopName: string,
  totalCoverage: number,
  lastTrigger: string,
  totalBenefits: number,
  actions: JSX.Element
};

function CoopManager() {
  const { user } = useUser();
  const navigate = useNavigate();

  const [selectedCoop, setSelectedCoop] = useState<Insuree>();
  const [isCoopDialogOpened, toogleCoopDialog] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  const detailCoopAction = useCallback((coopId: number) => {
    navigate(`/coopManager/${coopId}`);
  }, [navigate]);

  const removeCoopAction = useCallback((coopId: number) => {
    confirmAlert({
      title: 'Confirm delete',
      message: 'Are you sure you want to delete this cooperative ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => InsureeService.deleteInsuree(coopId)
            .then(res => {
              setReloadData((reload) => !reload);
            })
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  }, []);

  const seeCoopLogsAction = useCallback((coopId: number) => {
    navigate(`/admin/logs?meta=insuree-${coopId}`);
  }, [navigate]);

  const editCoopAction = useCallback((coop: Insuree) => {
    setSelectedCoop(coop);
    toogleCoopDialog(true);
  }, []);

  const updateCoops = useCallback((pageNb: number, pageSize: number, filter: string) => {
    return InsureeService.getAllInsureesPaginated(pageSize, pageNb, filter).then(res => {
      const contents = res[0].map(i => {
        const benefits = i.policies.map((p) => p.covers.map(c => c.benefits)).flat(2);
        const content: ContentCoops = {
          id: i.id,
          coopName: i.name,
          totalCoverage: i.policies.reduce((sum: number, p) => sum + p.covers.reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0), 0),
          lastTrigger: benefits.length > 0 ? benefits.reduce((acc, curr) => acc.date > curr.date ? acc : curr).date : '-',
          totalBenefits: benefits.length > 0 ? benefits.reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0) : 0,
          actions: <RowActions elementId={i.id} actions={[
            detailAction(() => detailCoopAction(i.id), false),
            ...user.role === Role.Admin ? [
              seeLogsAction(() => seeCoopLogsAction(i.id), false),
              editAction(() => editCoopAction(i), false),
              removeAction(() => removeCoopAction(i.id), false),
            ] : []
          ]} />
        };
        return content;
      });
      return { total: res[1], rows: contents };
    });
  }, [detailCoopAction, editCoopAction, removeCoopAction, seeCoopLogsAction, user.role]);

  return (
    <Page title="Cooperative Manager">
      <CustomTable
        headers={headersCoops}
        updateFunction={updateCoops}
        reloadData={reloadData}
        pageSizeOptions={[5, 10, 15]}
        searchLabel="Cooperative name"
        addButtonAction={user.role === Role.Insuree ? undefined : () => toogleCoopDialog(true)}
        addButtonLabel="New Cooperative"
        addButtonDisabled={user.role === Role.Insuree}
      />
      <CoopDialog
        coop={selectedCoop}
        opened={isCoopDialogOpened}
        onConfirm={(id: number) => {
          navigate(`/coopManager/${id}`);
        }}
        onClose={() => toogleCoopDialog(false)}
      />
    </Page>
  )
}

export default CoopManager;