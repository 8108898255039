import Benefit from "./Benefit";
import Province from "./Province";
import WeatherIndex from "./WeatherIndex";

export enum ConditionType {
  rain = 'rain',
  wind = 'wind'
}

interface WeatherCondition {
  id: number;
  province: Province;
  benefits: Benefit[];
  date: string;
  value: string;
  index: WeatherIndex;
  event: string;
  createdAt: string;
  createdBy: string;
}

export default WeatherCondition;