import {
  TextField,
  Typography,
  Button,
  Box
} from '@mui/material';
import { useMemo, useState } from 'react';
import { ContentProvinces } from '../../pages/Admin';
import ProvinceService from '../../services/Province';
import TriggerDot from '../TriggerDot';
import wcConfig from '../../configurations/weatherConditions.json';

interface Props {
  handleClose: Function;
  provinceToEdit: ContentProvinces | undefined;
}

function AddProvince({ handleClose, provinceToEdit }: Props) {
  const [name, setName] = useState<string>(provinceToEdit?.province ?? '');
  const [rainYellow, setRainYellow] = useState<number>(provinceToEdit?.trigger.rain.yellow_trigger ?? 0);
  const [rainOrange, setRainOrange] = useState<number>(provinceToEdit?.trigger.rain.orange_trigger ?? 0);
  const [rainRed, setRainRed] = useState<number>(provinceToEdit?.trigger.rain.red_trigger ?? 0);
  const [windYellow, setWindYellow] = useState<number>(provinceToEdit?.trigger.wind.yellow_trigger ?? 0);
  const [windOrange, setWindOrange] = useState<number>(provinceToEdit?.trigger.wind.orange_trigger ?? 0);
  const [windRed, setWindRed] = useState<number>(provinceToEdit?.trigger.wind.red_trigger ?? 0);

  const [reinsuranceCapacity, setReinsuranceCapacity] = useState<number>(provinceToEdit?.reinsuranceCapacity ?? 0);

  const [path, setPath] = useState<string>(provinceToEdit?.path ?? '');

  const yellowTriggerColor = useMemo(() => wcConfig.triggers.yellowTrigger.name.split(' ')[0], []);
  const orangeTriggerColor = useMemo(() => wcConfig.triggers.orangeTrigger.name.split(' ')[0], []);
  const redTriggerColor = useMemo(() => wcConfig.triggers.redTrigger.name.split(' ')[0], []);

  const handleChangeProvinceName = (value: string) => {
    setName(value);
  }

  const handleChangeReinsuranceCapacity = (value: string) => {
    setReinsuranceCapacity(parseFloat(value));
  }

  const handleChangeRainYellow = (value: string) => {
    setRainYellow(parseFloat(value));
  }
  const handleChangeRainOrange = (value: string) => {
    setRainOrange(parseFloat(value));
  }
  const handleChangeRainRed = (value: string) => {
    setRainRed(parseFloat(value));
  }

  const handleChangeWindYellow = (value: string) => {
    setWindYellow(parseFloat(value));
  }
  const handleChangeWindOrange = (value: string) => {
    setWindOrange(parseFloat(value));
  }
  const handleChangeWindRed = (value: string) => {
    setWindRed(parseFloat(value));
  }

  const submit = () => {
    ProvinceService.updateProvince({
      id: provinceToEdit?.id ?? 0,
      update: {
        name,
        triggers: {
          rain: {
            red_trigger: rainRed,
            orange_trigger: rainOrange,
            yellow_trigger: rainYellow
          },
          wind: {
            red_trigger: windRed,
            orange_trigger: windOrange,
            yellow_trigger: windYellow
          },
        },
        reinsuranceCapacity,
        path: path !== "" ? path.toUpperCase() : null
      },
    }).then(
      res => { handleClose() },
      err => { console.log(err) }
    );
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '75vh',
      overflow: 'auto'
    }}>
      <Typography variant='h5'>Update Province</Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: theme => theme.spacing(1, 4),
      }}>
        <TextField
          sx={{ width: '100%' }}
          label='Province Name'
          defaultValue={name}
          onChange={(e) => { handleChangeProvinceName(e.target.value) }}
        />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: theme => theme.spacing(1, 4),
      }}>
        <TextField
          sx={{ width: '100%' }}
          label='Province borders path (WKT MultiPolygon)'
          defaultValue={path}
          onChange={(e) => { setPath(e.target.value) }}
          type='Text'
          multiline={true}
          maxRows={10}
        />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: theme => theme.spacing(1, 4),
      }}>
        <TextField
          sx={{ width: '100%' }}
          label='Province reinsurance capacity (₱)'
          defaultValue={reinsuranceCapacity}
          onChange={(e) => { handleChangeReinsuranceCapacity(e.target.value) }}
        />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        m: 4,
        backgroundColor: 'background.default'
      }}>
        <Typography variant='h6'>Rain Triggers</Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          m: 1,
        }}>
          <TriggerDot level={0} />
          <TextField sx={{
            width: '100%',
            ml: 2
          }} label={yellowTriggerColor} type='Number'
            defaultValue={rainYellow}
            onChange={(e) => { handleChangeRainYellow(e.target.value) }}
          />
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          m: 1,
        }}>
          <TriggerDot level={1} />
          <TextField sx={{
            width: '100%',
            ml: 2
          }} label={orangeTriggerColor} type='Number'
            defaultValue={rainOrange}
            onChange={(e) => { handleChangeRainOrange(e.target.value) }}
          />
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          m: 1,
        }}>
          <TriggerDot level={2} />
          <TextField sx={{
            width: '100%',
            ml: 2
          }} label={redTriggerColor} type='Number'
            defaultValue={rainRed}
            onChange={(e) => { handleChangeRainRed(e.target.value) }}
          />
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        m: 4,
        backgroundColor: 'background.default'
      }}>
        <Typography variant='h6'>Wind Triggers</Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          m: 1,
        }}>
          <TriggerDot level={0} />
          <TextField sx={{
            width: '100%',
            ml: 2
          }} label={yellowTriggerColor} type='Number'
            defaultValue={windYellow}
            onChange={(e) => { handleChangeWindYellow(e.target.value) }}
          />
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          m: 1,
        }}>
          <TriggerDot level={1} />
          <TextField sx={{
            width: '100%',
            ml: 2
          }} label={orangeTriggerColor} type='Number'
            defaultValue={windOrange}
            onChange={(e) => { handleChangeWindOrange(e.target.value) }}
          />
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          m: 1,
        }}>
          <TriggerDot level={2} />
          <TextField sx={{
            width: '100%',
            ml: 2
          }} label={redTriggerColor} type='Number'
            defaultValue={windRed}
            onChange={(e) => { handleChangeWindRed(e.target.value) }}
          />
        </Box>
      </Box>
      <Button
        variant='contained'
        color='secondary'
        onClick={submit}
        sx={{
          width: theme => theme.spacing(16),
          alignSelf: 'center'
        }}
      >
        Submit
      </Button>
    </Box>
  );
}

export default AddProvince;