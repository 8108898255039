import Insuree from "./Insuree";

export enum Role {
  Admin = 'admin',
  SuperUser = 'super-user',
  Insuree = 'insuree'
}

interface User {
  id: number;
  email: string;
  role: Role;
  insuree: Insuree | null;
  name: string;
  active: boolean;
}

export default User;