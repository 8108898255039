import {
  Typography,
  Box,
  SxProps,
  Theme,
} from '@mui/material';

interface SingleDataCardProps {
  title: string;
  content: string;
  sx?: SxProps<Theme> | undefined
}

function SingleDataCard({ title, content, sx }: SingleDataCardProps) {
  return (
    <Box sx={{
      width: '100%',
      p: theme => theme.spacing(1.2, 2),
      m: theme => theme.spacing(0, 2),
      backgroundColor: 'background.paper',
      borderRadius: '3px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      ...sx
    }}>
      <Typography sx={{
        textAlign: 'center',
        fontSize: '1em',
        fontWeight: 'bold',
        fontStyle: 'italic',
        color: 'text.secondary',
        mr: 1
      }}>
        {title}
      </Typography>
      <Typography sx={{
        textAlign: 'center',
        fontSize: '1.5em',
      }}>
        {content}
      </Typography>
    </Box>
  );
}

export default SingleDataCard;