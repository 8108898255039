import moment from 'moment';
import { MirrorSyncStatus } from '../models/MirrorSyncStatus';
import ProvinceModel from '../models/Province';
import ApiService from './Api';
import UtilsService from '../services/Utils';
import wcConfig from '../configurations/weatherConditions.json';
import { ConditionType } from '../models/WeatherCondition';

class Admin {

  getMirrorSyncStatus(): Promise<MirrorSyncStatus> {
    return ApiService.get('/admin/mirror/status');
  }

  runMirroSync(): Promise<void> {
    return ApiService.post('/admin/mirror', {});
  }

  JSONtoCSV(arr: any[], columns: {title: string, key: string}[], delimiter = ',', withSep = false) {
    let _tmp = [
      columns.map(c => c.title).join(delimiter),
      ...arr.map(obj =>
        columns.reduce((acc, column) => `${acc}${!acc.length ? '' : delimiter}"${!obj[column.key] ? '' : obj[column.key]}"`, '')
      )
    ];
    if(withSep) {
      _tmp = [`sep=${delimiter}`, ..._tmp]
    }
    return _tmp.join('\n');
  }

  async getTriggerConfirmations(since: moment.Moment) {
    const yellowTriggerColor = wcConfig.triggers.yellowTrigger.name.split(' ')[0];
    const orangeTriggerColor = wcConfig.triggers.orangeTrigger.name.split(' ')[0];
    const redTriggerColor = wcConfig.triggers.redTrigger.name.split(' ')[0];
    const provinces: ProvinceModel[] = await ApiService.get(`/provinces/since?date=${since.toISOString()}`);
    const tmp = provinces.map(province => ({
      name: province.name,
      trigger_level: [
        { trigger: `rain ${yellowTriggerColor}`, level: UtilsService.formatPrecipitation(province.triggers.find((t) => t.index.name === ConditionType.rain)?.yellow ?? 0) },
        { trigger: `rain ${orangeTriggerColor}`, level: UtilsService.formatPrecipitation(province.triggers.find((t) => t.index.name === ConditionType.rain)?.orange ?? 0) },
        { trigger: `rain ${redTriggerColor}`, level: UtilsService.formatPrecipitation(province.triggers.find((t) => t.index.name === ConditionType.rain)?.red ?? 0) },
        { trigger: `wind ${yellowTriggerColor}`, level: UtilsService.formatWindSpeed(province.triggers.find((t) => t.index.name === ConditionType.wind)?.yellow ?? 0) },
        { trigger: `wind ${orangeTriggerColor}`, level: UtilsService.formatWindSpeed(province.triggers.find((t) => t.index.name === ConditionType.wind)?.orange ?? 0) },
        { trigger: `wind ${redTriggerColor}`, level: UtilsService.formatWindSpeed(province.triggers.find((t) => t.index.name === ConditionType.wind)?.red ?? 0) }
      ],
      trigger_data: province.covers.map(cover => cover.benefits.map(benefit => (
        {
          date: UtilsService.formatDate(benefit.date),
          type: benefit.weatherCondition.index.name,
          level:  UtilsService.formatWeatherCondition(benefit.weatherCondition.index.name, parseFloat(benefit.weatherCondition.value))
        }
      ))).flat(),
      payout_data: province.covers.map(cover => cover.benefits.map(benefit => ({
        cooperative: cover.policy.insuree.name,
        coverage: cover.amount,
        condition_type: benefit.weatherCondition.index.name,
        trigger: benefit.trigger === 'red_trigger' ? redTriggerColor : benefit.trigger === 'orange_trigger' ? orangeTriggerColor : yellowTriggerColor,
        payout_percentage: benefit.trigger === 'red_trigger' ? cover.red_payout : benefit.trigger === 'orange_trigger' ? cover.orange_payout : cover.yellow_payout,
        payout_amount: benefit.amount
      }))).flat(),
      emails: Array.from(new Set(province.covers?.map(cover => cover.policy.insuree.users?.map(user => user.email)).flat(2)))
    })).filter((prov) => prov.trigger_data.length > 0);
    return [
      'sep=;',
      Array.from(new Set(tmp.map(t => t.emails).flat())).join(','),
      ';;;;',
      ...tmp.filter(province => province.trigger_data.length > 0).map(province => [
        province.name,
        ';;;;',
        this.JSONtoCSV(
          province.trigger_data,
          [
            { title: 'Date', key: 'date' },
            { title: 'Weather condition', key: 'type' },
            { title: 'Recorded level', key: 'level' }
          ],
          ';'
        ),
        ';;;;',
        this.JSONtoCSV(
          province.trigger_level,
          [
            { title: 'Triggers', key: 'trigger' },
            { title: `Trigger level for ${province.name}`, key: 'level' }
          ],
          ';'
        ),
        ';;;;',
        this.JSONtoCSV(
          province.payout_data,
          [
            { title: 'Cooperative', key: 'cooperative' },
            { title: 'Coverage (in Philippine pesos)', key: 'coverage' },
            { title: 'Condition type', key: 'condition_type' },
            { title: 'Trigger', key: 'trigger' },
            { title: 'Payout %', key: 'payout_percentage' },
            { title: 'Payout amount (in Philippine pesos)', key: 'payout_amount' }
          ],
          ';'
        ),
      ]).flat()
    ].join('\n')
  }

}

export default new Admin();
