import currency from "../configurations/currency.json";
import wcConfig from "../configurations/weatherConditions.json";
import moment from 'moment';
import { ConditionType } from "../models/WeatherCondition";

class Utils {

  formatCurrency = (amount: number): string => {
    return amount ? Number(amount).toLocaleString('en-US', { minimumFractionDigits: 2 }) + currency.unit : '-';
  }

  formatPrecipitation(amount: number): string {
    return `${Number(amount).toLocaleString('en-US', { minimumFractionDigits: 3 })} ${wcConfig.conditions.rain.unit}`;
  }

  formatWindSpeed(amount: number): string {
    return `${(Number(amount)*wcConfig.conditions.wind.unitConversionMultiplier).toLocaleString('en-US', { minimumFractionDigits: 3 })} ${wcConfig.conditions.wind.unit}`;
  }

  formatWeatherCondition(type: string, amount: number): string {
    switch (type) {
      case 'wind':
        return this.formatWindSpeed(amount);
      default:
        return this.formatPrecipitation(amount);
    }
  }

  formatDate(date: string): string {
    const d = moment(date);
    if (d.isValid()) {
      return d.format('DD MMM YYYY');
    } else {
      return '-';
    }
  }

  formatTime(date: string): string {
    const d = moment(date);
    if (d.isValid()) {
      return d.local().format('hh:mm A') + ' UTC';
    } else {
      return '-';
    }
  }

  formatTimestamp(date: string): string {
    const d = moment(date);
    if (d.isValid()) {
      return d.format('DD MMM YYYY, H:mm:ss:SSS');
    } else {
      return '-';
    }
  }

  formatPercentage(amount: number): string {
    return amount ? amount.toLocaleString('en-US', { maximumFractionDigits: 0 }) + " %" : '-';
  }

  computePrecipitationDiff(value: number, trigger: number): string {
    return this.formatPercentage(((value - trigger) / value) * 100);
  }

  getXdaysBefore(daysBefore: number, date: string): string {
    const d = moment(date);
    if (d.isValid()) {
      d.add(-daysBefore, 'day');
      return d.format('YYYY-MM-DD');
    } else {
      return '-';
    }
  }
  getXdaysAfter(daysAfter: number, date: string): string {
    const d = moment(date);
    if (d.isValid()) {
      d.add(daysAfter, 'day');
      return d.format('YYYY-MM-DD');
    } else {
      return '-';
    }
  }

  formatGeometryForAdmin(path: { type: string, coordinates: number[][][][]}): string {
    return `${path.type.toUpperCase()}${JSON.stringify(
      path.coordinates.map((a) => 
        a.map(b => 
          b.map(c => `${c[0]} ${c[1]}`)
        )
      ))
      .replaceAll('"','')
      .replaceAll('[','(')
      .replaceAll(']',')')
    }`
  }

  toFirstLettersUpperCase(str: string): string {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    // Directly return the joined string
    return splitStr.join(' '); 
 }

}

export default new Utils();