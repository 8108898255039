import { Line } from 'react-chartjs-2';

import { Box } from '@mui/material';
import { ChartData, ChartOptions } from 'chart.js';
import 'chartjs-adapter-moment';
import moment from 'moment';
import wcConfig from '../configurations/weatherConditions.json';
import { useMemo } from 'react';
import { ConditionType } from '../models/WeatherCondition';

const REALTIME_LAG = 6; // days
const REALTIME_LAG_TIME_UNIT = 'day'; // days

const buildDatasets = (
  values: {date: string; value: number}[],
  label: string,
  unit: string,
  color: string,
  red_trigger?: number,
  orange_trigger?: number,
  yellow_trigger?: number,
  min_date?: number,
  max_date?: number,
  hide_legend?: boolean,
): { data: ChartData<'line'>, options: ChartOptions<"line"> } => ({
  data: {
    datasets: [
      {
        label,
        borderColor: color,
        backgroundColor: `${color}20`,
        data: values.map(p => {
          return { x: moment(p.date).valueOf(), y: p.value }
        }),
        fill: true,
        pointRadius: 0,
        pointHitRadius: 5,
      },
      ...(red_trigger ? [
        {
          label: wcConfig.triggers.redTrigger.name,
          borderColor: wcConfig.triggers.redTrigger.color,
          backgroundColor: wcConfig.triggers.redTrigger.color,
          pointRadius: 0,
          pointHitRadius: 0,
          data:  [
            { x:  min_date ? min_date : values.length > 0 ? moment(values[0].date).valueOf() : moment().valueOf(), y: red_trigger },
            { x: max_date ? max_date : values.length > 0 ? moment(values[values.length - 1].date).valueOf() : moment().valueOf(), y: red_trigger }
          ]
        }
      ] : []),
      ...(orange_trigger ? [
        {
          label: wcConfig.triggers.orangeTrigger.name,
          borderColor: wcConfig.triggers.orangeTrigger.color,
          backgroundColor: wcConfig.triggers.orangeTrigger.color,
          pointRadius: 0,
          pointHitRadius: 0,
          data: [
            { x:  min_date ? min_date : values.length > 0 ? moment(values[0].date).valueOf() : moment().valueOf(), y: orange_trigger },
            { x: max_date ? max_date : values.length > 0 ? moment(values[values.length - 1].date).valueOf() : moment().valueOf(), y: orange_trigger }
          ]
        }
      ] : []),
      ...(yellow_trigger ? [
        {
          label: wcConfig.triggers.yellowTrigger.name,
          borderColor: wcConfig.triggers.yellowTrigger.color,
          backgroundColor: wcConfig.triggers.yellowTrigger.color,
          pointRadius: 0,
          pointHitRadius: 0,
          data:  [
            { x:  min_date ? min_date : values.length > 0 ? moment(values[0].date).valueOf() : moment().valueOf(), y: yellow_trigger },
            { x: max_date ? max_date : values.length > 0 ? moment(values[values.length - 1].date).valueOf() : moment().valueOf(), y: yellow_trigger }
          ]
        }
      ] : [])
    ]
  },
  options: {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          stepSize: 1,
          displayFormats: {
            'day': 'DD/MM/YY'
          },
          tooltipFormat: 'DD MMM YYYY'
        },
        min: min_date,
        max: max_date
      },
      y: {
        min: 0
      }
    },
    plugins: {
      legend: {
        display: !hide_legend
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y + " " + unit;
            }
            return label;
          }
        }
      }
    }
  }
});

interface Props {
  values: {date: string; value: string}[];
  type: ConditionType;
  red_trigger?: number;
  orange_trigger?: number;
  yellow_trigger?: number;
  min_date?: number;
  max_date?: number;
  hide_legend?: boolean;
}

const addZerosIfDataIsMissing = (values: {date: string; value: number}[], min_date: moment.Moment | undefined = undefined, max_date: moment.Moment | undefined = undefined) => {
  const _values = [...values];
  let current = min_date ? min_date : _values.reduce((r, o) => moment(o.date).startOf('day').isBefore(r) ? moment(o.date).startOf('day') : r, moment()).startOf('day');
  const newest = max_date ? max_date : _values.reduce((r, o) => moment(o.date).startOf('day').isAfter(r) ? moment(o.date).startOf('day') : r, moment("0001-01-01")).startOf('day');
  while (current <= newest) {
    // eslint-disable-next-line no-loop-func
    const tmp = values.find((value) => moment(value.date).startOf('day').isSame(current));
    if (!tmp) {
      _values.push({date: current.toISOString(), value: 0});
    }
    current = current.add(1, 'day');
  }
  _values.sort((a, b) => moment(a.date).startOf('day') > moment(b.date).startOf('day') ? 1 : -1);
  return _values;
}

function WeatherChart({ values: _values, red_trigger: _red, orange_trigger: _orange, yellow_trigger: _yellow, type, min_date = undefined, max_date = undefined, hide_legend = false }: Props) {
  const label = useMemo(() => {
    switch (type) {
      case 'wind':
        return wcConfig.conditions.wind.label;
      default:
        return wcConfig.conditions.rain.label;
    }
  }, [type]);
  const unit = useMemo(() => {
    switch (type) {
      case 'wind':
        return wcConfig.conditions.wind.unit;
      default:
        return wcConfig.conditions.rain.unit;
    }
  }, [type]);
  const color = useMemo(() => {
    switch (type) {
      case 'wind':
        return wcConfig.conditions.wind.color;
      default:
        return wcConfig.conditions.rain.color;
    }
  }, [type]);
  const values = useMemo(() => {
    switch (type) {
      case 'wind':
        return addZerosIfDataIsMissing(
          _values
            .filter((condition, _, conditions) => {
              const sameDateConditions = conditions.filter(c => c.date === condition.date);
              if (Math.max(...sameDateConditions.map(c => parseFloat(c.value))) === parseFloat(condition.value)) {
                return true;
              }
              return false;
            })
            .map((condition) => ({ date: moment(condition.date).startOf('day').toISOString(), value: parseFloat(condition.value) * wcConfig.conditions.wind.unitConversionMultiplier})),
          moment(min_date),
          max_date ? 
            moment(max_date).isBefore(moment().add(-REALTIME_LAG, REALTIME_LAG_TIME_UNIT)) ?
              moment(max_date)
              : moment().add(-REALTIME_LAG, REALTIME_LAG_TIME_UNIT)
            : undefined
        );
      default:
        return _values.map((c) => ({...c, value: parseFloat(c.value)}));
    }
  }, [_values, max_date, min_date, type]);
  const { red_trigger, orange_trigger, yellow_trigger } = useMemo(() => {
    switch (type) {
      case 'wind':
        return {
          red_trigger: _red && _red * wcConfig.conditions.wind.unitConversionMultiplier,
          orange_trigger: _orange && _orange * wcConfig.conditions.wind.unitConversionMultiplier,
          yellow_trigger: _yellow && _yellow * wcConfig.conditions.wind.unitConversionMultiplier,
        };
      default:
        return { red_trigger: _red, orange_trigger: _orange, yellow_trigger: _yellow };
    }
  }, [_orange, _red, _yellow, type]);

  const { data, options } = buildDatasets(values, label, unit, color, red_trigger, orange_trigger, yellow_trigger, min_date, max_date, hide_legend );

  return (
    <Box width={'100%'} height={'100%'}>
      <Line
        data={data}
        options={options}
      />
    </Box>
  )
};

export default WeatherChart;
