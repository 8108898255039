import { Box, Button } from '@mui/material';

import Page from '../components/Page';
import CustomTable from '../components/CustomTable';
import { useCallback, useEffect, useState } from 'react';
import ActivityService from '../services/Activity';
import UtilsService from '../services/Utils';
import AdminSvc from '../services/Admin';
import '../components/modals/css/react-confirm-alert.css';

const headers = [
  { index: 'timestamp', title: 'Date', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'timestamp', title: 'Time', transformer: (d: string) => UtilsService.formatTime(d) },
  { index: 'email', title: 'User email' },
  { index: 'type', title: 'User type' },
  { index: 'coopName', title: 'Coop name', transformer: (d: string) => d === null ? '-' : d },
  { index: 'coopProvinces', title: 'Coop province names', transformer: (d: string) => d === null ? '-' : d },
];

interface Content {
  id: number,
  timestamp: string,
  email: string,
  type: string,
  coopName: string,
  coopProvinces: string
};

function Activity() {
  const [activities, setActivities] = useState<Content[]>([]);
  
  useEffect(() => {
    ActivityService.get().then(res => setActivities(res[0]));
  }, []);

  const updateActivities = useCallback((pageNb: number, pageSize: number, filter: string) => {
    return ActivityService.get(pageSize, pageNb).then(res => {
      const contents: Content[] = res[0];
      return { total: res[1], rows: contents }
    });
  }, []);

  return (
    <Page title="Activity">
      <Box sx={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <CustomTable
          headers={headers}
          updateFunction={updateActivities}
          disableSearch
        />
        <Button
          sx={{ width: 'fit-content', m: 2 }}
          variant='contained'
          color='secondary'
          href={`data:text/json;charset=utf-8,${encodeURIComponent(
            AdminSvc.JSONtoCSV(activities.map(a => ({
              date: UtilsService.formatDate(a.timestamp),
              time: UtilsService.formatTime(a.timestamp),
              email: a.email,
              type: a.type,
              coopName: a.coopName,
              provinceNames: a.coopProvinces ? a.coopProvinces.replaceAll(', ', '";"') : null
            })), [
              { title: 'Date', key: 'date' },
              { title: 'Time', key: 'time' },
              { title: 'User email', key: 'email' },
              { title: 'User type', key: 'type' },
              { title: 'Coop name', key: 'coopName' },
              { title: 'Coop province names', key: 'provinceNames' }
            ], ';', true)
          )}`}
          download="activity.csv"
        >
          Export to CSV
        </Button>
      </Box>
    </Page>
  );
}

export default Activity;